import { useContext, useMemo, createElement, Children } from 'react';
import { metadata, constants } from 'lib_ui-services';
import { contexts, Text, View, MenuShellItem, styled, testProperties, fromTheme } from 'lib_ui-primitives';
import HNode from '../../HNode';
import DropDownButton from '../_abstractComponent/DropDownButton';
import LoadingBoundary from '../contextProviders/LoadingBoundary';
import useBrand from '../../hooks/useBrand';
import ThemeEditor from '../Theme/ThemeEditor';

// For some reason, the Dark Mode switch will be dropped if you put this in the
// import above (e.g. import {createElement as rc} from 'react').
// We couldn't figure out why -- thinking maybe webpack does something weird here?
const rc = createElement;

const VersionContainer = styled(View).attrs({ name: 'version-container' })`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;
VersionContainer.displayName = 'VersionContainer';
const ProfileStyle = styled(View)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Version = styled(Text).attrs({ name: 'version' })`
    font-size: 10px;
    margin-left: 3px;
    margin-top: 6px;
    margin-right: 3px;
    color: ${fromTheme('unobtrusiveFontColor')};
`;

const _p = {
    useBrand,
    getUseCaseTitle: metadata.getUseCaseTitle,
    getUseCaseId: metadata.getUseCaseId
};
export const _private = _p;

export default function Menu(props) {
    const { children, hNode } = props || {};
    const session = useContext(contexts.SessionContext);
    if (session.role == null) {
        throw new Error('A login session exists, but no role was found for the user profile.');
    }
    const isSupport = session?.role?._id === constants.WELL_KNOWN_ROLE_IDS.SUPPORT;
    const release = metadata.getUseCaseRelease();
    const useCaseTitle = _p.getUseCaseTitle();
    // Filter to appropriate Menu components
    const menuItems = useMemo(() => {
        const children = hNode.children.map(hNode => {
            if (hNode.hNodeTypeGroup === 'menuSelection') {
                return rc(HNode, { hNode });
            } else {
                return null;
            }
        });
        return children;
    }, [hNode.children]);

    const otherItems = useMemo(() => {
        const c = Children.toArray(children).filter(
            component => component.props.hNode.hNodeTypeGroup !== 'menuSelection'
        );
        return c;
    }, [children]);

    // prettier-ignore
    return rc(ProfileStyle, null,
        rc(LoadingBoundary, null,
            rc(DropDownButton, {
                icon: 'person',
                id: props.id || 'profile',
                ...testProperties(hNode, 'profile')
            },
                ...menuItems,
                rc(MenuShellItem, { key: 'miVersion' },
                    () => rc(VersionContainer, null,
                        //eslint-disable-next-line no-undef
                        rc(Version, null, `Platform: v${__PACKAGE_VERSION__}`),
                        rc(Version, null, `${useCaseTitle}: v${release}`)
                    )
                ),
                isSupport && rc(ThemeEditor)
            ),
            ...otherItems
        )
    );
}
