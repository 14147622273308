import { useEffect, useState } from 'react';
import { metadata } from 'lib_ui-services';
import logging from '@sstdev/lib_logging';

/**
 * @typedef {{_meta:{ id: string, title: string, dataType?: string, prettyName?: string}, [key:string]:LegacyTypeDef }} LegacyTypeDef
 * @typedef {{_meta:{ id:string, title:string, dataType?:string, propertyName?:string}, [key:string]:TypeDef}} TypeDef
 * @typedef {{[key:string]:TypeDef | LegacyTypeDef}} DataModel
 */

export const _private = {
    getDictionary: metadata.getDictionary
};
/**
 *
 * @param {string} namespace
 * @param {string} relation
 * @returns { object | DataModel}
 */
export default function useDataModel(namespace, relation) {
    const [dataModel, setDataModel] = useState({});

    useEffect(() => {
        let allowStateUpdate = true;
        async function doAsync() {
            let dd = await _private.getDictionary();
            if (allowStateUpdate) {
                if (dd == null) {
                    logging.warn('No data dictionary was found to determine property types for the model.');
                    dd = {};
                }
                setDataModel(dd?.[namespace]?.[relation] || {});
            }
        }
        doAsync();
        return () => (allowStateUpdate = false);
    }, [namespace, relation, setDataModel]);
    return dataModel;
}
