import { session, socket } from 'lib_ui-services';

export default {
    verb: 'doingRemove',
    namespace: 'security',
    relation: 'profile',
    description: 'Log out the current user',
    priority: 10,
    logic
};

async function logic() {
    socket.disconnect();
    await session.logout();
}
