import { ObjectId, offlineResilientCommunication } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep, unset } = lodash;
import setDefaultValuesIfNecessary from '../../../../utilities/setDefaultValuesIfNecessary';
import trimStringWhitespaceIfNecessary from '../../../../utilities/trimStringWhitespaceIfNecessary';
import notHandledHere from './notHandledHere';
import getShortProfileForMeta from '../../../../utilities/getShortProfileForMeta';
export default {
    verb: 'willCreate',
    excludedNamespaceRelations: notHandledHere,
    priority: 10,
    prerequisites: [],
    description: 'Prepare to create the given record on the database',
    // this is the actual logic:
    logic
};

const _p = {
    setDefaultValuesIfNecessary,
    trimStringWhitespaceIfNecessary
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context }) {
    const newRecord = cloneDeep(data.newRecord);

    await _p.setDefaultValuesIfNecessary(context, newRecord);
    await _p.trimStringWhitespaceIfNecessary(context, newRecord);
    const currentTime = new Date().toISOString();
    const currentUser = getShortProfileForMeta(context);
    context.correlationId = context.correlationId || new ObjectId().toString();
    newRecord.meta = {
        ...newRecord.meta,
        createdBy: currentUser,
        createdTime: currentTime,
        modifiedBy: currentUser,
        modifiedTime: currentTime
    };
    unset(newRecord.meta, 'overriddenToDirty');
    // include the updated newRecord in the data before passing it to the getOfflineAction method
    data.newRecord = newRecord;
    const offlineAction = offlineResilientCommunication.getOfflineAction(
        newRecord.meta,
        context.correlationId,
        newRecord._id,
        data,
        context
    );

    return { ...data, offlineAction };
}
