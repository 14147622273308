import './createNonce';
import './setAttributePolyfill';
import './whyDidYouRender';
import 'intro.js/introjs.css';

// You can perform any async operations you need in here before the app starts up.
// This was built for preloading feature flag modules, but can be used for anything.
async function preStartup() {
    const startup = await import(/* webpackPrefetch: true */ './startup');
    startup.default();
}

preStartup();
