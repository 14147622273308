export function registerAuth0() {
    throw new Error('This should not be called for Auth0.');
}

export async function getCurrentSession() {
    throw new Error('This should not be called for Auth0.');
}

export async function getCurrentToken() {
    throw new Error('This should not be called for Auth0.');
}

export async function clearCurrentSession() {
    throw new Error('This should not be called for Auth0.');
}

export async function setCurrentSession() {
    throw new Error('This should not be called for Auth0.');
}

export async function find() {
    throw new Error('This should not be called for Auth0.');
}

export async function save() {
    throw new Error('This should not be called for Auth0.');
}

export async function clearUser() {
    throw new Error('This should not be called for Auth0.');
}
