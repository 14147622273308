import { useTheme } from 'styled-components';
import useCalculatedColumn from './useCalculatedColumn';
import useActiveRecord from '../../../hooks/useActiveRecord';
import useSplashRecord from '../../../hooks/useSplashRecord';
import useNavigationSelection from '../../../hooks/useNavigationSelection';
import getColumnCell from './getColumnCell';
import { hooks } from 'lib_ui-primitives';
const { useFeatureFlags } = hooks;
export const _private = {
    useActiveRecord,
    useCalculatedColumn,
    useNavigationSelection,
    useSplashRecord,
    getColumnCell
};

export default function useColumnCell(
    record,
    columnHNode,
    style,
    width,
    skipUndefined = false,
    platformSpecificValueLookup,
    setWidth = true
) {
    const theme = useTheme();

    const activeRecord = _private.useActiveRecord();
    const splashRecord = _private.useSplashRecord();
    const navigationSelection = _private.useNavigationSelection();
    const featureFlags = useFeatureFlags();
    const _width = setWidth && typeof width === 'number' ? width : undefined;

    const calculatedColumn = _private.useCalculatedColumn({
        record,
        columnHNode,
        style,
        skipUndefined,
        platformSpecificValueLookup,
        theme,
        width: _width,
        setWidth,
        featureFlags,
        activeRecord,
        splashRecord,
        navigationSelection
    });
    if (calculatedColumn != null) return calculatedColumn;

    return _private.getColumnCell({
        record,
        columnHNode,
        style,
        skipUndefined,
        platformSpecificValueLookup,
        theme,
        width: _width,
        setWidth,
        featureFlags,
        activeRecord,
        splashRecord,
        navigationSelection
    });
}
