import { useContext } from 'react';
import { hooks, contexts } from 'lib_ui-primitives';
import { constants } from 'lib_ui-services';
import useHasFeatureFlag from './useHasFeatureFlag';

const { retention } = constants;
const { SCOPE, DURATION, VISIBILITY } = retention;
const { useBbState } = hooks;

/**
 * @typedef {{
 *      _id:string,
 *      system:boolean,
 *      title:string,
 *      description:string,
 *      access:{
 *          defaultAccess:boolean,
 *          rights:{
 *             [pageId:string]:object
 *          }
 *      },
 *      dataRights:{
 *          [restrictedForeignKeyProperty:string]:Array<{_id:string, title:string}>
 *      }
 * } Role
 *
 * @typedef{{_isDefault:true}} EmptyRole
 */
const NO_ROLE = {
    _isDefault: true
};

const { SessionContext } = contexts;

/**
 *
 * @returns {[Role, true] | [ EmptyRole, false]} returns a role and a boolean indicating if that role is fully loaded.
 */
export default function useProfileRole() {
    const session = useContext(SessionContext);
    const clientDataRights = useHasFeatureFlag('clientDataRights');
    const { role: basicRole } = session || {};

    const roleRetention = {
        // DURATION.SESSION/VISIBILITY.PER_BROWSER: this value gets cleared on page reload or login/out
        duration: DURATION.SESSION,
        visibility: VISIBILITY.PER_BROWSER,
        scope: SCOPE.LOCAL,
        // the only reason we might not have an _id would be when running unit tests
        id: basicRole?._id || 'test'
    };

    /**
     * @type {[Role | EmptyRole, function(Role):void]}
     */
    const [role] = useBbState(NO_ROLE, 'role', roleRetention);
    // If the client data rights feature is turned off or if the role has been loaded because the current role
    // no longer is the NO_ROLE constant, then return ready === true.
    const ready = !clientDataRights || !role._isDefault;

    return [role, ready];
}
