import hashIds from 'hashids';
import { format } from 'date-fns';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';

export default {
    verb: 'willCreate',
    namespace: 'rex',
    relation: 'rma',
    description: 'Set some default RMA fields',
    priority: 10, //before the default willCreate
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'rex',
                relation: 'status',
                type: 'find'
            },
            query: {
                title: 'NEW',
                'meta.deleted': { $exists: false }
            }
        },
        {
            context: {
                verb: 'get',
                namespace: 'rex',
                relation: 'status',
                type: 'find'
            },
            query: {
                title: 'Closed',
                'meta.deleted': { $exists: false }
            }
        }
    ],
    //this is the actual logic:
    logic: willCreate
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function willCreate({ data, prerequisiteResults, context }) {
    const newStatus = prerequisiteResults[0].result[0];
    const closedStatus = prerequisiteResults[1].result[0];
    const rmaSaveAndClose = getAllFeatureFlags(context).includes('RMASaveAndClose') ?? false;
    //we need to set something to make it unique, but this will be overriden on the server.
    data.newRecord.rmaNo = getRmaHash(context);

    data.newRecord.dateCreated = new Date();
    if (rmaSaveAndClose && data.newRecord.closeOnSubmit) {
        delete data.newRecord.closeOnSubmit;
        data.newRecord['rex:status'] = { title: closedStatus.title, _id: closedStatus._id };
    } else {
        data.newRecord['rex:status'] = { title: newStatus.title, _id: newStatus._id };
    }

    return data;
}

/**
 * Used to get mostly-random, fixed length RMA # strings in the format "TMPO3ZP"
 * @param {{user:{activeTenantId:string}}} context
 */
function getRmaHash(context) {
    let { activeTenantId } = context.user || {};
    const randomUpper = 99999;
    const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const padTo = 5;
    let hash = new hashIds(activeTenantId, padTo, alphabet);
    return 'TMP' + hash.encode(Math.floor(Math.random() * (1 + randomUpper))) + format(new Date(), 'yyMM');
}
