import { ObjectId, http, metadata, constants, network } from 'lib_ui-services';
import pThrottle from 'p-throttle';
import defaultBulkRemove from '../../namespace/relation/bulk/doingBulk_namespace_relation_remove';
import getShortProfileForMeta from '../../../../utilities/getShortProfileForMeta';

const { notificationTypes } = constants;
const MAX_REQUEST_PER_TIME_FRAME = 50;
const REQUEST_RATE_TIME_FRAME = 60000; // 1 minute in ms
const BATCH_SIZE = 250;

export default {
    verb: 'doingBulk',
    namespace: 'item',
    relation: 'item',
    type: 'remove',
    description: 'Send a message to the server to perform a mass delete operation on the selected items',
    logic: doingBulkRemove,
    onError
};

const _p = {
    metadata,
    pThrottle,
    getNetworkStatus: network.getStatus,
    handleOfflineRemovals: defaultBulkRemove.logic
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: {selection:Array<{_id:string}>};
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingBulkRemove({ data, dispatch, context, log }) {
    if (!data?.selection?.length) return;
    const { type, namespace, relation } = context;
    const meta = createMetadata(context);

    const totalRecords = data.selection.length;
    const prettyName = _p.metadata.getPrettyRelationName(namespace, relation);
    log.info(`Removing ${totalRecords} ${prettyName} records.`);

    const networkStatus = await _p.getNetworkStatus();
    if (!networkStatus.isOnline) {
        // If the user is offline, fallback to the default bulk remove logic
        return _p.handleOfflineRemovals({ data, dispatch, context, log });
    }

    const url = `/api/${namespace}/${relation}/${ObjectId()}/bulk`;
    const sendBulkRemove = createThrottledBulkRemove(url);

    const { showProgress, closeProgress } = createProgressHandler(dispatch, prettyName, totalRecords);
    const failed = [];

    await showProgress(0, 'Starting removal process');
    toggleSpinner(dispatch, true);

    // If the user is online, send the record ids to silo_rules-engine in batches for bulk removal
    for (let i = 0; i < totalRecords; i += BATCH_SIZE) {
        const chunk = data.selection.slice(i, i + BATCH_SIZE);
        const batch = createBatch(chunk, meta, namespace, relation, type);

        try {
            // Send the batch to silo_rules-engine for processing
            const result = await sendBulkRemove(batch);
            if (result?.failed?.length) failed.push(...result.failed);
        } catch (error) {
            log.error(`Error removing batch ${i / BATCH_SIZE + 1}:`, error);
        }

        await showProgress(
            Math.min(i + BATCH_SIZE, totalRecords),
            `Removed ${i + BATCH_SIZE} out of ${totalRecords} ${prettyName} records`
        );
    }

    await closeProgress(`Completed removal of ${totalRecords} ${prettyName} records`);
    toggleSpinner(dispatch, false);

    return { result: { totalRecords, failed } };
}

function onError({ error, dispatch }) {
    closeProgressDialog(dispatch);
    toggleSpinner(dispatch, false);
    throw error;
}

/**
 * Utility functions
 */
function createMetadata(context) {
    return {
        deleted: true,
        deletedBy: getShortProfileForMeta(context),
        deletedTime: new Date().toISOString()
    };
}

function createThrottledBulkRemove(url) {
    const throttle = _p.pThrottle({
        limit: 1,
        interval: REQUEST_RATE_TIME_FRAME / MAX_REQUEST_PER_TIME_FRAME
    });
    return throttle(batch => http.post(url, batch));
}

function createProgressHandler(dispatch, prettyName, totalRecords) {
    const showProgress = progressNotifier(dispatch, `Removing ${prettyName} records`, totalRecords);
    return {
        showProgress,
        closeProgress: async message => {
            await showProgress(totalRecords, message);
            closeProgressDialog(dispatch);
        }
    };
}

function createBatch(chunk, meta, namespace, relation, type) {
    return {
        data: chunk.map(item => item._id),
        meta,
        namespace,
        relation,
        type
    };
}

function toggleSpinner(dispatch, isSpinning) {
    const message = isSpinning ? 'Removing' : 'Removal Complete';
    dispatch(
        { busy: isSpinning, source: 'httpSync', message, type: notificationTypes.SYNC_BUSY },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
}

function progressNotifier(dispatch, title, total) {
    return (current, description) =>
        dispatch(
            { mainTitle: 'Removing', description, title, current, total },
            { verb: 'update', namespace: 'application', relation: 'progress' }
        );
}

function closeProgressDialog(dispatch) {
    return dispatch({ mainTitle: 'Removing' }, { verb: 'reset', namespace: 'application', relation: 'progress' });
}
