import { authentication, session } from 'lib_ui-services';

export const _private = { authentication };

export default {
    verb: 'doingUpdate',
    namespace: 'security',
    relation: 'profile',
    type: 'selectTenant',
    prerequisites: [],
    description: 'Select a tenant when logging in',
    //this is the actual logic:
    logic: selectTenant
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function selectTenant({ data }) {
    const { tenant, useCase } = data;
    if (tenant == null || useCase == null) {
        return Promise.reject(new Error('Please select a use case to continue.'));
    }
    session.setActiveUseCaseAndTenant({ tenant, useCase });
}
