import { ObjectId, constants } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep } = lodash;
import getShortProfileForMeta from '../../../../utilities/getShortProfileForMeta';
import setDefaultValuesIfNecessary from '../../../../utilities/setDefaultValuesIfNecessary';
import trimStringWhitespaceIfNecessary from '../../../../utilities/trimStringWhitespaceIfNecessary';
const { useCaseIds } = constants;

export default {
    verb: 'willCreate',
    priority: 10,
    prerequisites: [],
    description: 'Prepare to create the given record on the database',
    namespace: 'deploy',
    relation: 'environment',
    type: 'singleRecordForm',
    excludedUseCaseIds: [useCaseIds.ONE_TOUCH],
    // this is the actual logic:
    logic
};

const _p = {
    setDefaultValuesIfNecessary,
    trimStringWhitespaceIfNecessary
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context }) {
    const newRecord = cloneDeep(data.newRecord);
    await _p.setDefaultValuesIfNecessary(context, newRecord);
    await _p.trimStringWhitespaceIfNecessary(context, newRecord);
    const currentTime = new Date().toISOString();
    const currentUser = getShortProfileForMeta(context);
    context.correlationId = context.correlationId || new ObjectId().toString();
    newRecord.meta = {
        ...newRecord.meta,
        createdBy: currentUser,
        createdTime: currentTime,
        modifiedBy: currentUser,
        modifiedTime: currentTime
    };
    // include the updated newRecord in the data before passing it to the getOfflineAction method
    data.newRecord = newRecord;
    return data;
}
