import notHandledHere from './notHandledHere';
import { metadata, ObjectId, offlineResilientCommunication } from 'lib_ui-services';
import getShortProfileForMeta from '../../../../utilities/getShortProfileForMeta';
export default {
    verb: 'willRemove',
    excludedNamespaceRelations: notHandledHere.filter(f => f.namespace !== 'file'),
    prerequisites: [],
    //we want this to happen before checking dependencies
    priority: 10,
    description: 'Confirm deletion and add meta data',
    //this is the actual logic:
    logic
};

const _p = {
    metadata
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @typedef {{record:{_id:string, meta:object}}} UnconfirmedRemovePayload
 * @typedef {{trueDelete?: boolean, skipConfirm?:boolean, id:string, meta:object}} ConfirmedRemovePayload
 *
 * @param {{
 *   data: UnconfirmedRemovePayload|ConfirmedRemovePayload;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {ConfirmedRemovePayload}
 */
async function logic({ data, context, dispatch }) {
    //if the data already has a meta object on the root, we don't need to do anything, just pass through
    //most likely came from sockets
    if (data.skipConfirm) {
        // but removing metadata:useCaseDetail does a cascading delete,
        // we DON"T need to confirm each remove, but we DO need the decoration stuff
        if (data.needsDecoration) {
            const { namespace, relation } = context;
            const titleAlternative = _p.metadata.getTitleAlternative(namespace, relation);
            const title = data.record[titleAlternative];
            return decorate(data, title, context);
        }
        return data;
    }

    //otherwise, make sure user really wants to remove
    return new Promise((resolve, reject) => {
        const { namespace, relation } = context;
        const titleAlternative = _p.metadata.getTitleAlternative(namespace, relation);
        const prettyName = _p.metadata.getPrettyRelationName(namespace, relation);
        const title = data.record[titleAlternative];
        dispatch(
            {
                message: `Delete ${prettyName} ${data.record[titleAlternative]}?`,
                okAction: () => {
                    try {
                        resolve(decorate(data, title, context));
                    } catch (err) {
                        reject(err);
                    }
                },
                cancelAction: () => {
                    reject(new Error(`Delete of ${prettyName} ${title} canceled.`));
                }
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}

function decorate(data, title, context) {
    const {
        record: { _id: id }
    } = data;
    const currentTime = new Date().toISOString();
    const currentUser = getShortProfileForMeta(context);
    context.correlationId = context.correlationId || new ObjectId().toString();
    const meta = { deleted: true, deletedBy: currentUser, deletedTime: currentTime };
    const offlineAction = offlineResilientCommunication.getOfflineAction(
        meta,
        context.correlationId,
        id,
        data,
        context
    );
    return { id, title, meta, offlineAction };
}
