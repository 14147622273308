import { metadata, constants, sessionStorage, globalConfig, getRouter } from 'lib_ui-services';
import lodash from 'lodash';
const { cloneDeep } = lodash;
import conversions from '@sstdev/lib_epc-conversions';
import { COMMON_COLOR_SCHEME, errors } from 'lib_ui-primitives';
import logging from '@sstdev/lib_logging';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';

const { useCaseIds } = constants;

const _p = {
    asyncConfirmationModal
};

export const _private = _p;
export default {
    verb: 'willCreate',
    namespace: 'item',
    relation: 'item',
    priority: 20,
    prerequisites: [],
    description: 'Copy tagId from assetNo if applicable',
    useCaseIds: [
        useCaseIds.ASSET_TRACKING,
        useCaseIds.AMERICAN_WATER_ASSET_TRACKING,
        useCaseIds.ITEM_TRACKING,
        useCaseIds.WISE_ID,
        useCaseIds.WORK_IN_PROCESS
    ],
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

const FIVE_MINUTES = 300000;
/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context, dispatch }) {
    try {
        const newRecord = cloneDeep(data.newRecord);
        const { namespace, relation } = context;

        // TagId is required for item tracking list checkboxes
        const displayInAscii = getAllFeatureFlags(context).includes('displayInAscii') ?? false;

        // If the user requests that we assign a tag, display the PowerSliderPopup to get one.
        if (newRecord.tagId == null && sessionStorage.getKey(`item:item.assignTag|${getRouter().getRouteStateKey()}`)) {
            const { reads } = await dispatch(
                {},
                { namespace: 'application', relation: 'component', verb: 'open', type: 'PowerSliderPopup' },
                //defaultTimeout is production is 5 minutes as well, but just to make it not anoying in dev of localhost
                //because a timeout throws an error, and then just continues with the code below the catch without notice....
                //5 minutes _should_ be enough that in production its occurance should be rare enough
                //that we don't need to build in any additional logic to handle that neatly
                { awaitResult: true, timeOutMs: FIVE_MINUTES }
            );
            //validate read results
            if (reads.length < 1) {
                throw new errors.ValidationError('Assignment Failed: No RFID tag detected.', {});
            }
            reads.sort((a, b) => {
                if (a.rssi < b.rssi) return -1;
                if (a.rssi === b.rssi) return 0;
                return 1;
            });
            let tagId = reads[0].tagId;

            if (displayInAscii) {
                // strip null characters if this value is known to translate to ascii
                tagId = tagId.replace(/^(00)+/, '').replace(/(00)+$/, '');
            } else {
                // otherwise, strip leading zeros (they are not significant digits)
                tagId = tagId.replace(/^0+/, '');
            }
            const { result: tagLookupResult } = await dispatch(
                { tagId },
                { verb: 'get', namespace: 'item', relation: 'item', type: 'find' },
                true
            );
            // If this tag is already assigned to a different asset.
            if (tagLookupResult.length > 0) {
                const assetNo = tagLookupResult[0].assetNo;
                // Users cannot reassign tags to a different asset.  Must be higher access level.
                if (isUser(context)) {
                    throw new errors.ValidationError(`This tag is already assigned to asset ${assetNo}.`, {});
                }
                // Trying to assign the asset to the same tag.
                if (assetNo === newRecord.assetNo) {
                    throw new errors.ValidationError(`This tag is already assigned to asset ${assetNo}.`, {});
                }
                const shouldProceed = await _p.asyncConfirmationModal(dispatch, assetNo, newRecord.assetNo);
                if (!shouldProceed) {
                    throw new errors.ValidationError('Tag assignment cancelled.', {});
                }

                // Remove this tag's assignment from other assets that have it.
                await Promise.all(
                    tagLookupResult.map(async item => {
                        const otherRecordThatHadTag = cloneDeep(item);
                        delete otherRecordThatHadTag.tagId;
                        await dispatch(
                            {
                                oldRecord: item,
                                newRecord: otherRecordThatHadTag,
                                propertiesToUnset: [{ propertyName: 'tagId' }]
                            },
                            { verb: 'update', namespace: 'item', relation: 'item' },
                            true
                        );
                    })
                );
            }

            newRecord.tagId = tagId;
            dispatch(
                {
                    isError: false,
                    message: `Assigning tag ID ${newRecord.tagId}.`,
                    timeout: globalConfig().notificationTimeout
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
        } else if (
            [constants.useCaseIds.ITEM_TRACKING, constants.useCaseIds.WORK_IN_PROCESS].includes(
                context.session.useCase['metaui:useCase']._id
            )
        ) {
            const titleAlternative = metadata.getTitleAlternative(namespace, relation, 'title');
            newRecord.tagId =
                newRecord.tagId ??
                (displayInAscii
                    ? conversions.ascii.toHex(newRecord[titleAlternative], true)
                    : newRecord[titleAlternative]);
        }

        // include the updated newRecord in the data before passing it to the getOfflineAction method
        data.newRecord = newRecord;
        return data;
    } catch (err) {
        if (err instanceof errors.ValidationError) {
            dispatch(
                {
                    isError: true,
                    message: err.message,
                    timeout: globalConfig().notificationTimeout
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
        } else if (err?.form?.[0] !== null) {
            dispatch(
                {
                    isError: true,
                    message: err.form[0],
                    timeout: globalConfig().notificationTimeout
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
            throw new Error(err.form[0]);
        } else {
            logging.error(err);
        }
        throw err;
    }
}
function isUser(context) {
    return context.session.role.title === constants.WELL_KNOWN_ROLES.USER;
}

async function asyncConfirmationModal(dispatch, oldAssetNo, newAssetNo) {
    return new Promise(resolve => {
        const message2 =
            newAssetNo != null
                ? `Are you sure you want to assign it to new asset ${newAssetNo}?`
                : 'Are you sure you want to assign it to a new asset?';
        dispatch(
            {
                message: [`This tag is already assigned to asset ${oldAssetNo}.`, message2],
                okButtonText: 'YES',
                icon: 'warning',
                iconColor: COMMON_COLOR_SCHEME.warn,
                okAction: () => resolve(true),
                cancelAction: () => resolve(false)
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}
