import { constants } from 'lib_ui-services';
import defaultGet from '../../namespace/relation/doingGet_namespace_relation';
import conversions from '@sstdev/lib_epc-conversions';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';

export default {
    verb: 'doingGet',
    namespace: 'item',
    relation: 'item',
    useCaseIds: [constants.useCaseIds.WORK_IN_PROCESS],
    // After the local lookup
    priority: (defaultGet.priority ?? 0) - 10,
    prerequisites: [
        {
            context: {
                verb: 'getFromServer',
                status: undefined
            },
            skipLookup: ({ data }) => {
                // if data.result == null, null is NOT > 0, so this would work correct in that scenario too:
                return data.result?.length > 0;
            },
            query: ({ data, context }) => {
                // if the query is for a tagId, a nd the tenant has a displayInAscii flag, the server
                // expects the tagId to be in ascii format.
                const displayInAscii = getAllFeatureFlags(context).includes('displayInAscii') ?? false;
                if (data.criteria?.tagId && displayInAscii) {
                    data.criteria.tagId = conversions.ascii.fromHex(data.criteria.tagId);
                }
                return {
                    criteria: {
                        searchCriteria: data.query?.criteria ?? data.criteria ?? data.query ?? data
                    }
                };
            }
        }
    ],
    //this is the actual logic:
    logic: checkServerForItemIfNecessary
};

function checkServerForItemIfNecessary({ data, prerequisiteResults }) {
    if (!data.result?.length && prerequisiteResults[0]?.result?.length > 0) {
        return { ...data, result: prerequisiteResults[0].result };
    }

    return data;
}
