import { useMemo } from 'react';
import { filters } from 'lib_ui-services';
import useHasFeatureFlag from './useHasFeatureFlag';
import useProfileRole from './useProfileRole';
import useDataModel from './useDataModel';

const { createDataFilter } = filters;

const _p = {
    useProfileRole,
    useDataModel,
    useHasFeatureFlag,
    createDataFilter
};

export const _private = _p;

/**
 * generates a filter hNode based on the dataRights of the current user's role
 * To be added to the filters as generated of the rest of the metadata on this hNode.
 * @param {import('../../../types').HNode} hNode some sort of data list generating hNode (e.g. grid or dropdown)
 * @returns {import('../../../types').HNode}
 */
export default function useDataRightsHNode(hNode) {
    const { foreignNamespace, foreignRelation, namespace: _namespace, relation: _relation } = hNode;
    const namespace = foreignNamespace || _namespace;
    const relation = foreignRelation || _relation;
    // Get this data here to avoid an async operation in createDataFilter
    const dataModel = _p.useDataModel(namespace, relation);
    const [role, isReady] = _p.useProfileRole();
    const clientDataRights = _p.useHasFeatureFlag('clientDataRights');

    return useMemo(() => {
        if (!clientDataRights) return undefined;

        let hNode = {
            id: 'dataRightsFilter',
            hNodeType: 'DataRights',
            hNodeTypeGroup: 'filter',
            namespace,
            relation
        };
        if (!isReady) {
            // While the isReady already should be used by any calling code to prevent rendering
            // adding a filter that filters out everything will work as fallback if they don't.
            // (creating a filter for a property that doesn't exist, and requiring it to have a value of
            // true should, by definition, prevent all data from being displayed.)
            // Ideally, the component should not be rendered at all, so this is just a fallback.
            return { ...hNode, filter: { __loading: true } };
        }

        // system roles never have a data filter
        // if there are no dataRights on the role, we don't need to filter
        if (role.system || !role.dataRights || !Object.keys(role.dataRights).length) {
            return undefined;
        }
        // otherwise, it depends on the properties on the data model if this relation needs filtering
        const filter = _p.createDataFilter(namespace, relation, dataModel, role);
        if (!filter) {
            return undefined;
        }
        return { ...hNode, filter };
    }, [isReady, role, namespace, relation, dataModel, clientDataRights]);
}
