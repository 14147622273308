/**
 * @module lib_ui-services/session
 * @description This keeps track of the user's session, including their profile, use case, and tenant.
 * The session also tracks whether the user has accepted the EULA and MLA for the active use case and
 * tenant, and the deployment group number used by the tenant.
 *
 */
import logging from '@sstdev/lib_logging';
import globalConfig from '../globalConfig';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import getRouter from '../getRouter';
import hasAuthParams from './hasAuthParams';
import {
    saveProfile,
    getProfile,
    getActiveUseCaseAndTenant,
    setActiveProfileId,
    setActiveUseCaseAndTenant as _setActiveUseCaseAndTenant,
    clearActiveProfileId,
    clearActiveUseCaseAndTenant,
    getProfileWithUserNameAndPin,
    onCacheChange,
    profileWithPinExists
} from './sessionCache';
import getIfEulaAndMlaAccepted from './getIfEulaAndMlaAccepted';
import simpleChangeObserver from '../simpleChangeObserver';
import { getStatus } from '../network';
import getFullURL from '../http/getFullURL';

const { onChange, publishChange } = simpleChangeObserver();

let _p = {
    _groupPath: '',
    auth0: {},
    createAuth0Client,
    clearActiveProfileId,
    clearActiveUseCaseAndTenant,
    getActiveUseCaseAndTenant,
    getBackboneUser,
    getIfEulaAndMlaAccepted,
    getNetworkStatus: getStatus,
    getProfile,
    getProfileWithUserNameAndPin,
    getRouter,
    getSessionData,
    globalConfig,
    hasAuthParams,
    httpGet: rawGet,
    login,
    logout,
    onCacheChange,
    profileWithPinExists,
    saveProfile,
    setActiveProfileId,
    setActiveUseCaseAndTenant: _setActiveUseCaseAndTenant
};

export const _private = _p;

export const onSessionChange = onChange;

export async function init() {
    const { auth0 } = _p.globalConfig();
    const { protocol, host, groupNumber } = _p.getRouter();
    _p._groupPath = groupNumber ? `/g/${groupNumber}` : '/g/0';
    const audience = `${protocol}//${host}/api`;
    const redirect_uri = `${protocol}//${host}${_p._groupPath}/`;
    // Login and get user info
    _p.auth0 = await _p.createAuth0Client({
        domain: auth0.AUTH0_DOMAIN,
        clientId: auth0.AUTH0_CLIENT_ID,
        authorizationParams: {
            audience,
            redirect_uri
        },
        useRefreshTokens: true,
        useRefreshTokensFallback: true,
        // Copied to the /dist path (served by the node server) from node_modules during webpack build.
        workerUrl: `${_p._groupPath}/auth0-spa-js.worker.production.js`
    });

    const networkStatus = await _p.getNetworkStatus();
    let profile, session;
    if (networkStatus.isServerReachable) {
        // automatically redirect to the Auth0 login page.
        profile = await _p.login();
        if (profile == null) {
            // Probably just redirected to the login page.
            return;
        }
        // TODO: Fix this on the server side so that there is JUST _id - no userId.
        await _p.setActiveProfileId(profile._id ?? profile.userId);
        await _p.saveProfile(profile);
        session = await _p.getSessionData(profile);
    } else {
        const throwIfMissing = false;
        const profile = await _p.getProfile(throwIfMissing);
        if (profile != null) {
            session = await _p.getSessionData(profile);
        } else {
            const profileWithPinExists = await _p.profileWithPinExists();
            if (!profileWithPinExists) {
                throw new Error(
                    'This device appears to have no network connection and no previous login.  You will need to connect to the internet and login at least once to use this application.'
                );
            }
            session = { useOfflinePin: true };
        }
    }

    createSessionSubscriptions();

    return session;
}

export async function createSessionSubscriptions() {
    _p.onCacheChange(async () => {
        if (_p.loggingOut) return;
        try {
            const session = await _p.getSessionData();
            const { groupNumber } = session ?? {};
            // A different group number (than that in the url) could existing in the tenant, so
            // we need to update the group path.
            _p._groupPath = groupNumber ? `/g/${groupNumber}` : '';
            publishChange('session', session);
        } catch (error) {
            logging.error(`[SESSION] Error updating session: ${error.message}`);
        }
    });
}

/**
 * Retrieves session data based on the provided profile.
 * If no profile is provided, it retrieves the profile using _p.getProfile().
 * It then retrieves the active use case and tenant using _p.getActiveUseCaseAndTenant(profile).
 * Finally, it checks if the EULA and MLA are accepted for the use case and tenant using _p.getIfEulaAndMlaAccepted(useCase, tenant).
 * The group number is extracted from the tenant object.
 * Returns an object containing the group number and the session data.
 *
 * @param {import('../../../types').Profile} [_profile] - The user profile.
 * @returns {Promise<import('../../../types').Session>} - An object containing the session data.
 */
export async function getSessionData(_profile) {
    const profile = _profile ?? (await _p.getProfile());
    if (profile == null) {
        logging.error('[SESSION] No profile found.');
        return _p.logout();
    }
    let eulaAccepted, mlaAccepted, groupNumber, role, allFeatureFlags;
    const { useCase, tenant } = (await _p.getActiveUseCaseAndTenant(profile)) ?? {};
    if (useCase != null && tenant != null) {
        ({ eulaAccepted, mlaAccepted } = _p.getIfEulaAndMlaAccepted(useCase) ?? {});
        groupNumber = useCase['deploy:group']?.title[1] ?? 0;
        role = useCase['identity:role'];
        allFeatureFlags = useCase.featureFlags ?? [];
    }
    const briefUserReference = { _id: profile._id ?? profile.userId, displayName: profile.displayName };
    const session = {
        briefUserReference,
        profile,
        useCase,
        tenant,
        eulaAccepted,
        mlaAccepted,
        groupNumber,
        role,
        allFeatureFlags,
        loggingOut: !!_p.loggingOut
    };
    return session;
}

export async function login() {
    const { protocol, host, goToLocation } = _p.getRouter();
    const isAuthenticated = await _p.auth0.isAuthenticated();
    if (!isAuthenticated) {
        if (_p.hasAuthParams()) {
            const result = await _p.auth0.handleRedirectCallback();
            if (result.appState?.returnTo) {
                goToLocation(result.appState?.returnTo);
            } else {
                goToLocation(`${_p._groupPath}/`);
            }
        } else {
            // FYI - The group path here is based off of the request URL.
            // It is possible that this will be a different group than that requested for
            // the tenant and the Backbone.js component will redirect if that is the case.
            logging.info(
                `[AUTHENTICATION][NAVIGATION] Going to auth0 login page with login success redirecting to ${protocol}//${host}${_p._groupPath}/.`
            );
            await _p.auth0.loginWithRedirect({
                redirect_uri: `${protocol}//${host}${_p._groupPath}/`
            });
            return;
        }
    }
    let auth0User;
    auth0User = await _p.auth0.getUser();
    if (!auth0User) {
        throw new Error('Logged in, but no user found.');
    }
    const backboneUser = await _p.getBackboneUser(auth0User);

    return { ...auth0User, ...backboneUser };
}

export async function loginWithPin(payload) {
    const { email, pin } = payload;
    const userName = email;

    if (userName == null || userName === '') {
        return Promise.reject(new Error('An email must be provided.'));
    }
    if (pin == null || pin === '') {
        return Promise.reject(new Error('A pin must be provided.'));
    }
    const profile = await _p.getProfileWithUserNameAndPin(userName, pin);
    if (profile == null) {
        return Promise.reject(new Error('Invalid email or pin.'));
    }
    const session = await _p.getSessionData(profile);
    publishChange('session', session);
    return Promise.resolve(session);
}

export async function getToken() {
    return _p.auth0.getTokenSilently();
}

export async function logout(clearSessionCache = true) {
    // Prevents the selectTenant immediately when the tenant is cleared.
    _p.loggingOut = true;
    if (clearSessionCache) {
        await _p.clearActiveUseCaseAndTenant();
        await _p.clearActiveProfileId();
    }
    const status = await _p.getNetworkStatus();
    if (status.isServerReachable) {
        logging.info('[AUTHENTICATION][NAVIGATION] Logging out and redirecting to auth0 login page.');
        const fullGroupRoot = getFullURL('/');
        await _p.auth0.logout({ logoutParams: { returnTo: fullGroupRoot } });
    } else {
        // This will clear tokens etc, but redirect to auth0 login page.
        await _p.auth0.logout({ openUrl: false });
        const fullOfflinePinUrl = getFullURL('/offlinePin');
        _p.getRouter().loadPage(fullOfflinePinUrl);
    }
}

async function getBackboneUser() {
    const token = await _p.auth0.getTokenSilently({ detailedResponse: true });
    const accessToken = token.access_token;
    let backboneUser = await _p.httpGet(_p._groupPath + '/api/identity/self', {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json'
    });

    // No backbone user found.
    if (!backboneUser || !backboneUser.tenant?.[0].useCase?.[0]) {
        throw new Error('The user was authenticated, but no access was found for this user.');
    }

    return backboneUser;
}

//we need to bypass the http.get functionality, as otherwise we'd end up with a circular reference
async function rawGet(url, headers) {
    const params = {
        method: 'GET',
        headers
    };
    const response = await fetch(url, params);
    if (response.ok) {
        return response.json();
    } else {
        logging.error(`[AUTHENTICATION] Failed to authorize. ${response.status} - ${response.statusText}`);
    }
}

export async function setActiveUseCaseAndTenant(activeUseCaseAndTenant) {
    // This can be confusing so check duck type
    if (!activeUseCaseAndTenant?.useCase || !activeUseCaseAndTenant?.tenant) {
        throw new Error('Invalid use case and tenant object.');
    }
    await _p.setActiveUseCaseAndTenant(activeUseCaseAndTenant);
}

export async function setLicenseAccepted(acceptedLicense) {
    const { type } = acceptedLicense;
    const { useCase, tenant } = await _p.getActiveUseCaseAndTenant();
    const eulaAccepted = type === 'EULA',
        mlaAccepted = type === 'MLA';

    // update the active tenants use case with the accepted license
    const profile = await _p.getProfile();
    const tenantIndex = profile.tenant.findIndex(t => t._id === tenant._id);
    if (tenantIndex === -1) {
        throw new Error(
            'Tenant not found for accepted license.  There may be a problem with the user profile configuration.'
        );
    }
    const useCaseIndex = profile.tenant[tenantIndex].useCase.findIndex(u => u._id === useCase._id);
    if (useCaseIndex === -1) {
        throw new Error(
            'A use case was not found for the accepted license.  There may be a problem with the user profile configuration.'
        );
    }
    if (eulaAccepted) {
        profile.tenant[tenantIndex].useCase[useCaseIndex].needEulaAccepted = false;
    }
    if (mlaAccepted) {
        profile.tenant[tenantIndex].useCase[useCaseIndex].needMlaAccepted = false;
    }
    // This should trigger a cascade of events that will update the session object.
    await _p.saveProfile(profile);
}

export async function removeActiveUseCaseAndTenant() {
    await _p.clearActiveUseCaseAndTenant();
    const fullGroupRoot = getFullURL('/');
    _p.getRouter().loadPage(fullGroupRoot);
}
