import { createElement as rc } from 'react';

import { Caret, fromTheme, Text, View, Pressable, styled, webOnlyStyles, nativeOnlyStyles } from 'lib_ui-primitives';

import useToggle from '../../hooks/useToggle';
import Collapse from '../helpers/Collapse';
import UseCaseItem from './UseCaseItem';
import { useTheme } from 'styled-components';

let TenantWrapper = styled(View).attrs(props => ({
    name: 'tenant-wrapper',
    ['aria-expanded']: !props.isCollapsed
}))`
    display: flex;

    flex-direction: column;
    margin: 0 ${({ theme }) => (theme.mobile ? 0 : '60px')};
`;

TenantWrapper = webOnlyStyles(TenantWrapper)`
    cursor: pointer;
    flex-shrink:0;
    :not(:first-of-type) {
        margin-top: 16px;
    }
`;

let TenantTitle = styled(Pressable).attrs({ name: 'tenant-title' })`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
TenantTitle = nativeOnlyStyles(TenantTitle)`
    margin-top: ${fromTheme('viewMargin')};
    margin-bottom: ${fromTheme('viewMargin')};
`;

const ToggleCaret = styled(Caret).attrs({ name: 'toggle-caret' })`
    margin-right: 6px;
    height: 8px;
    width: 8px;
`;
ToggleCaret.displayName = 'ToggleCaret';

let LineDivider = styled(View)`
    flex-grow: 1;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 1px;
    border-bottom-color: ${fromTheme('borderColor')};
    margin-left: 16px;
    height: 3px;
`;
LineDivider = webOnlyStyles(LineDivider)`
    border-style: solid;
`;
const WrappedCollapse = styled(Collapse)`
    flex-wrap: wrap;
`;

/**
 * @typedef {Object} Props
 * @property {import('../../../../types').Tenant} tenant
 * @property {import('../../../../types').UseCase} [selectedUseCase]
 * @property {boolean}  [startCollapsed]
 * @property {(event: React.MouseEvent, useCase: import('../../../../types').UseCase) => void} onClick
 */
/**
 * @type {import('../../../../types').FC<Props>} TenantItem
 */
const TenantItem = ({ tenant, selectedUseCase, selectedTenant, startCollapsed, onClick }) => {
    const [isCollapsed, toggleCollapse] = useToggle(startCollapsed);
    const { mobile } = useTheme();

    const tenantTitle = tenant['identity:tenant'].title;
    let title = `${tenantTitle} (${tenant.useCase.length})`;
    if (mobile && tenantTitle.length > 30) {
        title = `${tenantTitle.substring(0, 28)}…  (${tenant.useCase.length})`;
    }

    //const useCase = useMemo(() => ({ ...useCase, 'identity:tenant': tenant['identity:tenant'] }), [tenant]);

    // prettier-ignore
    return rc(TenantWrapper, { isCollapsed },
        rc(TenantTitle, { onClick: () => toggleCollapse(x => !x) },
            rc(ToggleCaret, { isOpen: !isCollapsed, }),
            rc(Text, null, title),
            rc(LineDivider)
        ),
        rc(WrappedCollapse, { isCollapsed },
            tenant.useCase.map(useCase =>
                rc(UseCaseItem, {
                    key: `${useCase['metaui:useCase']._id}-${tenant['identity:tenant']._id}`,
                    active: selectedUseCase &&
                        selectedUseCase['metaui:useCase']._id === useCase['metaui:useCase']._id &&
                        selectedTenant['identity:tenant']._id === tenant['identity:tenant']._id,
                    onClick,
                    useCase,
                    tenant
                })
            )
        )
    );
};

export default TenantItem;
