import getGlobalConfig from '../globalConfig';

const _p = { getGlobalConfig };
export const _private = _p;
export default function getFullURL(path, hostnameOverride) {
    const config = _p.getGlobalConfig();
    let port = ['', undefined, null].includes(config.port) ? '' : `:${config.port}`;
    let protocol = config.protocol;
    if (hostnameOverride) {
        if (!hostnameOverride?.includes('localhost') || hostnameOverride?.includes(':')) {
            port = '';
        }
        /**The protocol property of the Location interface is a string representing the protocol scheme of the URL, including the final ':' */
        protocol = hostnameOverride?.includes('localhost') ? 'http:' : 'https:';
    }

    // prettier-ignore
    const groupPath = path.startsWith('/g/')
        ? ''
        : config.groupNumber != null
            ? `/g/${config.groupNumber}`
            : '';
    return `${protocol}//${hostnameOverride ?? config.hostname}${port}${groupPath}${path}`;
}
