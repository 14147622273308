import RulesEngine from 'rulesengine.io';
import { default as rules, extendedRulesArrayRepository } from './rules';
import logging from '@sstdev/lib_logging';
import { eventSink as EventSink } from 'lib_ui-services';
import { errors } from 'lib_ui-primitives';
import omit from 'lodash/omit';
import memoizer from './ruleMemoizer';
function eventSink() {
    return EventSink();
}
let rulesEngine;
let unsubscribes = [];

const _p = { RulesEngine, EventSink, flattenAllRules };
export const _private = _p;

const ignoredNsRel = {
    application: ['predefinedRecords']
};

// IMPORTANT: Do not add "submit" to any of the supported steps below, as that
// will conflict with the form logic.  Instead, consider adding your related
// logic to the existing CRUD type verbs (e.g. Validate, Create, Update or Remove).
const supportedFormSteps = {
    cancel: ['doingCancel'],
    copy: ['willCopy', 'doingCopy'],
    edit: ['willEdit', 'doingEdit', 'didEdit'],
    find: ['doingFind'],
    mark: ['willMark', 'doingMark'],
    new: ['willNew', 'doingNew', 'didNew'],
    validate: ['doingValidate'],
    change: ['willChange', 'doingChange', 'didChange']
};

const supportedApplicationSteps = {
    clearCache: ['doingClearCache'],
    // application_progress uses 'update' and 'reset' verbs (defined elsewhere)
    // These two are for application notification (doingReset and doingRemove are also used by notifications)
    pop: ['doingPop'],
    view: ['doingView'],
    //We only get the event when the action already happened. So only didNavigate rules.
    navigate: ['willNavigate', 'doingNavigate', 'didNavigate'],
    //for transition period only(?), so we don't need additional blockly.
    logout: ['willRemove', 'doingRemove'],
    //these things are a bit of a hack anyway....
    cli: ['doingCli'],
    read: ['didRead']
};

const supportedServiceSteps = {
    register: ['doingRegister'],
    stop: ['doingStop'],
    startup: ['doingStartup'],
    reset: ['doingReset']
};

const supportedCrudSteps = {
    get: ['willGet', 'doingGet', 'didGet'],
    getMore: ['doingGetMore'],
    getFromServer: ['doingGetFromServer'],
    //list/grid components will pull data directly from loki using the useDbView.
    //We need some way to still post-process as applicable (e.g. to add depreciation) with the didGet rules
    didGet: ['defaultDidGet', 'didGet'],
    create: ['willCreate', 'doingCreate', 'didCreate'],
    update: ['willUpdate', 'doingUpdate', 'didUpdate'],
    upsert: ['willUpsert', 'doingUpsert', 'didUpsert'],
    remove: ['willRemove', 'doingRemove', 'didRemove'],
    count: ['willCount', 'doingCount', 'didCount'],
    bulk: ['willBulk', 'doingBulk', 'didBulk'], //only supports remove for now
    //12/22/2021 For now, we'll add all phases for commit. _Probably_ we can reduce commit to `didCommit` only, as we use optimistic commits on the client
    commit: ['willCommit', 'doingCommit', 'didCommit'],
    rollback: ['willRollback', 'doingRollback', 'didRollback'],
    export: ['willExport', 'doingExport'],
    purge: ['doingPurge'],
    //For triggering resyncs of the given relation
    refresh: ['doingRefresh']
};

export default function setupRulesEngine(session) {
    logging.debug('[RE] Setting up Rules Engine');
    if (unsubscribes && unsubscribes.length) {
        const temp = unsubscribes;
        unsubscribes = [];
        temp.map(unsubscribe => unsubscribe());
    }

    const rulesRepo = getRepository();

    let [subscribe, publish, request] = eventSink();

    // awaitResultOrOptions is overloaded in this function to allow either a boolean or an object
    // containing options for how the dispatch should be treated.  This is to avoid problems
    // with backward compatibility.
    const dispatch = async (payload, context, awaitResultOrOptions) => {
        let options = {};
        let awaitResult = awaitResultOrOptions;
        // resolve overload.
        if (typeof awaitResultOrOptions === 'object') {
            options = { ...awaitResultOrOptions };
            awaitResult = options.awaitResult ?? false;
        }
        if (context.eventBoundaryContext) {
            [, publish, request] = _p.EventSink(context.eventBoundaryContext);
        }
        let modifiedPayload = payload;
        if (context.status === 'failure' && payload.error instanceof Error) {
            const { error, ...otherPayload } = payload;
            if (error instanceof errors.ValidationError) {
                modifiedPayload = { ...otherPayload, errors: error.validationObject, originalError: error };
            } else {
                modifiedPayload = { ...otherPayload, errors: { field: {}, form: [error] }, originalError: error };
            }
        }
        if (awaitResult) {
            return request(modifiedPayload, context, undefined, undefined, options);
        }
        return publish(modifiedPayload, context, options);
    };
    const states = ['success', 'failure'];
    const steps = {
        ...supportedCrudSteps,
        ...supportedApplicationSteps,
        ...supportedFormSteps,
        ...supportedServiceSteps
    };
    const contextFilter = context => {
        return omit(context, ['eventBoundaryContext', 'session', 'hNode']);
    };
    const workflowStackFilter = workflowStack => {
        return omit(workflowStack, ['originalContext']);
    };
    const reLogging = {
        debug: (message, context, workflowStack) =>
            logging.debug('[RE]' + message, contextFilter(context), workflowStackFilter(workflowStack)),
        info: (message, context, workflowStack) =>
            logging.info('[RE]' + message, contextFilter(context), workflowStackFilter(workflowStack)),
        warn: (message, context, workflowStack) => {
            if (message._isUserError) {
                logging.info('[RE]' + message, contextFilter(context), workflowStackFilter(workflowStack));
            } else {
                logging.warn('[RE]' + message, contextFilter(context), workflowStackFilter(workflowStack));
            }
        },
        error: (message, context, workflowStack) => {
            if (message._isUserError) {
                logging.info('[RE]' + message, contextFilter(context), workflowStackFilter(workflowStack));
            } else {
                logging.error('[RE]' + message, contextFilter(context), workflowStackFilter(workflowStack));
            }
        }
    };

    rulesEngine = new _p.RulesEngine(rulesRepo, {
        steps,
        dispatch,
        states,
        enableworkflowStack: true,
        logging: reLogging,
        memoizer
    });

    const execute = async (payload, context) => {
        if (ignoredNsRel[context.namespace]?.includes(context.relation)) {
            return;
        }
        const newContext = {
            session,
            ...context
        };
        //generate workflow using cached workflow if available
        const workflow = await rulesEngine.createWorkflow(newContext);
        if (workflow.length === 0) {
            logging.debug('[RE] No workflow found.');
            if (context.status == null) {
                // avoid infinite loop by checking status
                // Don't attempt to execute an empty workflow, but do let the eventSink
                // know that none were found, so it can respond correctly.
                return publish(
                    { errors: { field: {}, form: ['No workflow found.'] } },
                    { ...context, status: 'failure' }
                );
            }
            return;
        }
        logging.debug('[RE]' + workflow.toString());
        // execute workflow
        await rulesEngine.execute(payload, workflow, newContext);
    };

    unsubscribes = Object.keys(steps).flatMap(verb => [
        subscribe({ verb }, execute),
        subscribe({ verb, status: 'success' }, execute),
        subscribe({ verb, status: 'failure' }, execute)
    ]);

    publish(
        { eventSink: eventSink() },
        { verb: 'startup', namespace: 'application', relation: 'rulesEngine', status: 'success' },
        { bubbleAlways: true }
    );
}

// Flatten all rules into a single array and construct the rules repository with them.
function getRepository() {
    const flattenedRules = flattenAllRules(rules);
    return new extendedRulesArrayRepository(flattenedRules);
}

// Avoid drilling into non-rule paths when flattening rules
const EXCLUDED_KEYS = ['registeredServiceQueues', 'readState'];
function flattenAllRules(pathHash, rules = [], depth = 0) {
    if (depth >= 30) {
        throw new Error(
            'Rule depth limit exceeded.  This probably means you have a circular reference and you need to add a value to the EXCLUDED_KEYS constant.'
        );
    }
    Object.entries(pathHash).forEach(([key, value]) => {
        if (key !== 'default') {
            if (typeof value === 'object' && value != null) {
                if (value?.verb != null) {
                    rules.push(value);
                } else {
                    if (!EXCLUDED_KEYS.includes(key)) {
                        flattenAllRules(value, rules, depth + 1);
                    }
                }
            }
        }
    });
    return rules;
}
