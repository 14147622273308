import { constants } from 'lib_ui-services';
import { hooks } from 'lib_ui-primitives';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';

/**
 *  * Selection Retention:
 *  Scope: Local, PerRoute, Global
 *  Visibility: PerBrowser (later: PerUser, PerTenant)
 *  Duration: Request, Session, Always
 * @type {import('lib_ui-services/src/constants/retention').Retention}
 */
const { SCOPE, VISIBILITY, DURATION } = constants.retention;
const { useBbState } = hooks;

export default {
    verb: 'doingGet',
    namespace: 'identity',
    relation: 'userRole',
    priority: 10,
    description: "Get the user user's role",
    logic: doingGet
};

export const _private = { getBbState: useBbState.getDirect };

async function doingGet({ data = {}, context }) {
    const { 'identity:role': basicRole } = context?.user || {};
    //can't put this in the top level, as we need to return "something"
    const clientDataRights = getAllFeatureFlags(context).includes('clientDataRights') ?? false;
    if (!clientDataRights) {
        return { ...data, result: [basicRole] };
    }

    const roleRetention = {
        // DURATION.SESSION/VISIBILITY.PER_BROWSER: this value gets cleared on page reload or login/out
        duration: DURATION.SESSION,
        visibility: VISIBILITY.PER_BROWSER,
        scope: SCOPE.LOCAL,
        // the only reason we might not have an _id would be when running unit tests
        id: basicRole?._id || 'test'
    };

    const role = await _private.getBbState('role', roleRetention);
    return { ...data, result: [role] };
}
