import logging from '@sstdev/lib_logging';

export default function getIfEulaAndMlaAccepted(useCase) {
    if (!useCase) {
        throw new Error('No active use case found.');
    }
    if (useCase.needEulaAcceptance) {
        logging.info('[SESSION] EULA acceptance required.');
    }
    if (useCase.needMlaAcceptance) {
        logging.info('[SESSION] MLA acceptance required.');
    }
    return { eulaAccepted: !useCase?.needEulaAcceptance, mlaAccepted: !useCase?.needMlaAcceptance };
}
