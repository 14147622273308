import { errors as _errors } from 'lib_ui-primitives';
import basicValidation from '../../namespace/relation/doingValidate_namespace_relation';
import { constants } from 'lib_ui-services';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';

export default {
    verb: 'doingValidate',
    namespace: 'item',
    relation: 'transaction',
    description: 'Report back to the user if any items were excluded due to not being checked out.',
    priority: (basicValidation.priority || 0) + 5, //we want this to run BEFORE basic validation
    useCaseIds: [constants.useCaseIds.FREIGHT_RUNNER],
    prerequisites: [
        // check locally
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'transaction',
                type: 'find'
            },
            query: ({ data }) => {
                const barcode = data?.newRecord?.barcode?.trim();
                if (!barcode) {
                    //avoid lookup, as there is nothing to look for. Throw error to abort prerequisite workflow
                    // (does not abort entire workflow!)
                    throw new Error('No `barcode` passed in. No Lookup Required');
                }
                let since12Hours = new Date();
                since12Hours.setHours(since12Hours.getHours() - 12);
                const query = {
                    barcode,
                    'meta.createdTime': { $gte: since12Hours.toISOString() }
                };
                return query;
            }
        },
        // and check remotely
        {
            context: {
                verb: 'getFromServer',
                namespace: 'item',
                relation: 'transaction'
            },
            skipLookup: ({ data }) => {
                const barcode = data?.newRecord?.barcode?.trim();
                // skip if there is no barcode. as there is nothing to look for.
                // doingGetFromServer will shortcut and return [].
                return !barcode;
            },
            query: ({ data }) => {
                const barcode = data?.newRecord?.barcode?.trim();
                let since12Hours = new Date();
                since12Hours.setHours(since12Hours.getHours() - 12);
                const query = {
                    criteria: {
                        searchCriteria: {
                            barcode,
                            'meta.createdTime': { $gte: since12Hours.toISOString() }
                        }
                    }
                };
                return query;
            }
        }
    ],
    logic: doingValidate
};
/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingValidate(message) {
    // First check if the barcode length is right:
    const featureFlags = getPalletBarcodeFeatureFlags(message.context);

    const acceptedLengths = featureFlags
        .map(ff => {
            try {
                return Number.parseInt(ff.replace('palletBarcodeLength', ''));
            } catch (error) {
                return;
            }
        })
        .filter(ff => !!ff);

    if (acceptedLengths.length) {
        if (!acceptedLengths.includes(message.data?.newRecord?.barcode?.trim()?.length)) {
            message.dispatch?.(
                {
                    isError: true,
                    message: 'Invalid Scan',
                    type: constants.notificationTypes.SOUND,
                    duration: 500,
                    volume: 1
                },
                {
                    verb: 'pop',
                    namespace: 'application',
                    relation: 'notification'
                }
            );
            throw new _errors.ValidationError('Unable to submit scan.', {
                barcode: `Pallet barcode should be one of the following lengths: ${acceptedLengths.join(', ')}.`
            });
        }
    }
    // Next check if there is already a read for that tag today
    const [localResult = {}, remoteResult = {}] = message.prerequisiteResults || [];
    if (localResult.result?.length || remoteResult.result?.length) {
        message.dispatch?.(
            {
                isError: true,
                message: 'Scan Rejected',
                type: constants.notificationTypes.SOUND,
                duration: 500,
                volume: 1
            },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        throw new _errors.ValidationError('Unable to submit scan.', {
            barcode: 'You already scanned this pallet barcode today.'
        });
    }

    message.dispatch?.(
        {
            isError: false,
            message: `Scan ${message.data?.newRecord?.barcode} Accepted`,
            type: constants.notificationTypes.SOUND,
            // beepType: 'sine',
            duration: 500,
            volume: 1
        },
        {
            verb: 'pop',
            namespace: 'application',
            relation: 'notification'
        }
    );
    return message;
}

function getPalletBarcodeFeatureFlags(context) {
    return getAllFeatureFlags(context).filter(flag => flag?.startsWith('palletBarcodeLength')) || [];
}
