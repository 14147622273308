import logging from '@sstdev/lib_logging';
import { ObjectId } from 'lib_ui-services';
import rule from '../../CRUD/namespace/relation/doingCreate_namespace_relation';
import getShortProfileForMeta from '../../../utilities/getShortProfileForMeta';
export default {
    verb: 'doingPop',
    namespace: 'application',
    relation: 'notification',
    description: 'keep track of notifications',
    //this is the actual logic:
    logic: doingPop
};

async function doingPop({ data, dispatch, context }) {
    if (!data.message || data.message === '' || isEmptyObject(data.message)) {
        logging.error('A blank notification was attempted.');
        return;
    }
    await dispatch(data, { ...context, relation: 'ui-notification' });
    if (!data.addToList) return;

    const _id = new ObjectId().toString();
    const currentTime = new Date().toISOString();
    const currentUser = getShortProfileForMeta(context);
    context.correlationId = context.correlationId || new ObjectId().toString();
    const meta = {
        createdBy: currentUser,
        createdTime: currentTime,
        modifiedBy: currentUser,
        modifiedTime: currentTime
    };
    return rule.logic({
        data: {
            newRecord: {
                ...data,
                _id,
                meta,
                seen: false
            }
        },
        context
    });
}

function isEmptyObject(obj) {
    return obj.constructor === Object && Object.keys(obj).length === 0;
}
