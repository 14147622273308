import notHandledHere from './notHandledHere';
import { metadata, database } from 'lib_ui-services';

export default {
    verb: 'willRemove',
    excludedNamespaceRelations: [...notHandledHere, { namespace: 'metadata' }],
    prerequisites: [],
    priority: 5,
    description: 'Prevent the deletion if there are any records referencing this record.',
    //this is the actual logic:
    logic: willRemove
};

const _p = {
    getDictionary: metadata.getDictionary,
    metadata,
    database
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @typedef {{trueDelete?: boolean, skipConfirm?:boolean, id:string, title:string, meta:object}} ConfirmedRemovePayload
 *
 * @param {{
 *   data: ConfirmedRemovePayload;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {ConfirmedRemovePayload}
 */
async function willRemove({ data, context, dispatch }) {
    //if the data already has a meta object on the root, we don't need to do anything, just pass through
    //most likely came from sockets
    if (data.skipConfirm) return data;

    const { namespace, relation } = context;
    const { id, title = '' } = data;
    const referencingRelations = getReferencingRelations(await _p.getDictionary(), namespace, relation);
    const getReferenceCountFor = getReferenceCount(id, namespace, relation);
    for (const [referencingNamespace, referencingRelation] of referencingRelations) {
        const count = await getReferenceCountFor(referencingNamespace, referencingRelation);
        if (count > 0) {
            const prettyName = _p.metadata.getPrettyRelationName(namespace, relation);
            const referencingPrettyName = _p.metadata.getPrettyRelationName(referencingNamespace, referencingRelation);
            const message = `Unable to delete ${prettyName} ${title}. It is used by ${count} ${referencingPrettyName}${
                count > 1 ? 's' : ''
            }.`;
            dispatch(
                {
                    addToList: false,
                    isError: true,
                    message
                },
                { verb: 'pop', namespace: 'application', relation: 'notification' }
            );
            throw new Error(message);
        }
    }
    return data;
}

function getReferencingRelations(dictionary, foreignNamespace, foreignRelation) {
    const key = `${foreignNamespace}:${foreignRelation}`;
    let result = [];
    const namespaces = Object.entries(dictionary).filter(
        ([namespace]) => !['application', 'transaction', '_meta'].includes(namespace)
    );
    for (const [namespace, value] of namespaces) {
        const relations = Object.entries(value).filter(
            ([relation]) => !relation.endsWith('-patch') && !['locationHistory', '_meta'].includes(relation)
        );
        relations.forEach(([relation, typeDef]) => {
            if (typeDef?.[key]) {
                result.push([namespace, relation]);
            }
        });
    }
    return result;
}

const getReferenceCount = (id, namespace, relation) => async (referencingNamespace, referencingRelation) => {
    const key = `${namespace}:${relation}._id`;
    const query = {
        [key]: id,
        'meta.deleted': { $exists: false }
    };
    const db = _p.database.get();
    const result = await db.count(query, { namespace: referencingNamespace, relation: referencingRelation });
    return result;
};
