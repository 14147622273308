import { createElement as rc, useState, useEffect, useRef, useCallback, useContext } from 'react';
import lodash from 'lodash';
const { omit } = lodash;
import { h3, Button, testProperties, sorts, contexts, hooks } from 'lib_ui-primitives';
import DynamicFieldEntryRow from './DynamicFieldEntryRow';
import { metadata } from 'lib_ui-services';
import createTreePositionGetter from '../../../utilities/createTreePositionGetter';
import { ReverseEntries, StyledDynamicFieldEntry, Header, Title } from './styles';
const { usePersistentState } = hooks;

const _p = {
    getFields,
    usePersistentState
};

export const _private = _p;
/**
 * This component allows a user to select, at runtime, what type of
 * information they would like to edit.  At the time of this writing,
 * it is used for mass updates.  A user will select from a list of
 * valid fields they can edit (based on the data dictionary for the
 * namespace/relation), and then set the desired value for that field.
 * In the case of mass updates, this component supplies the value
 * which will then be applied to all records selected (by a different
 * component) for the given namespace/relation.
 */
export default function DynamicFieldEntry(props) {
    const {
        hNode,
        hNode: { foreignNamespace, foreignRelation, excludedFields = [], treePosition },
        currentRoute
    } = props ?? { hNode: {} };
    const focusContext = useContext(contexts.FocusContext);
    const { title } = props;
    // The types of fields (i.e. hNodes) that can be used. These are
    // rendered in a dropdown of an entry when the user first adds
    // a new entry.
    const [allowedEntries, _setAllowedEntries] = useState([]);
    // Entries represent either new rows in the component for which the
    // user has not already selected an allowed value field (i.e. hNode)
    // or rows for the field (hNode) and corresponding value the user has
    // supplied for that field.
    const [entries, setEntries, entriesReady] = _p.usePersistentState(`dynamicEntries-${hNode.id}`, []);
    const [ready, setReady] = useState(false);
    const [addAllowed, setAddAllowed] = useState(false);
    const validEntries = useRef([]);

    useEffect(() => {
        focusContext.disableFocusManagement = true;
        focusContext.reportChanges = false;
        return () => {
            focusContext.disableFocusManagement = false;
            focusContext.reportChanges = true;
        };
    }, [focusContext]);

    useEffect(() => {}, [focusContext]);
    // Proxy for original setAllowedEntries which sorts the entries by title;
    const setAllowedEntries = useCallback(newEntries => {
        _setAllowedEntries(prev => {
            let results = newEntries;
            // avoid sorting (and rerendering) if this is the same reference
            if (results === prev) return prev;
            // Handle a function setter
            if (typeof newEntries === 'function') {
                results = newEntries(prev);
                // avoid sorting (and rerendering) if the function resturns the same reference
                if (results === prev) {
                    return prev;
                }
            }
            return sorts.sortByField(results, 'title');
        });
    }, []);

    useEffect(() => {
        async function doAsync() {
            const fields = await _p.getFields(foreignNamespace, foreignRelation);
            validEntries.current = fields;
            setAllowedEntries(fields);
            setReady(true);
        }
        doAsync();
    }, [foreignNamespace, foreignRelation, setAllowedEntries, treePosition]);

    useEffect(() => {
        setAddAllowed(allowedEntries.length > 0 && entries.length < validEntries.current.length);
    }, [entries, allowedEntries]);

    useEffect(() => {
        if (ready && entriesReady) {
            const firstUnassignedIndex = entries.findIndex(e => !e.hNode);
            focusContext.focusIfExists(`normal-dropdown-input-fdd${firstUnassignedIndex}`);
        }
    }, [entries, focusContext, ready, entriesReady]);

    const initialAllowedSet = useRef(false);
    useEffect(() => {
        // One time only, set allowed entries to those that are not in already selected
        // and not explicitly excluded in the blockly excludedFields value.
        if (initialAllowedSet.current === false && entriesReady && ready) {
            const entryIds = entries.map(e => e.hNode?.id ?? '0');
            const newAllowed = allowedEntries.filter(
                a => !entryIds.includes(a.id ?? 0) && !excludedFields.includes(metadata.getPathToProperty(a))
            );
            setAllowedEntries(newAllowed);
            initialAllowedSet.current = true;
        }
    }, [entriesReady, ready, allowedEntries, entries, excludedFields, setAllowedEntries]);

    function add() {
        focusContext.reportChanges = true;
        setEntries(prev => {
            const max = prev?.reduce((maxSoFar, v) => Math.max(maxSoFar, v.reverseSequence), -1) ?? -1;
            return [...prev, { reverseSequence: max + 1, sequence: max + 1 }];
        });
    }

    function remove(index, childHNode) {
        focusContext.reportChanges = true;
        setEntries(prev => {
            const newArray = [...prev];
            newArray.splice(index, 1);
            return newArray;
        });
        setAllowedEntries(prev => {
            if (childHNode != null && !prev.includes(childHNode)) {
                return [...prev, childHNode];
            }
            return prev;
        });
    }

    function entryUsed(index, childHNode) {
        setEntries(prev => {
            const newArray = [...prev];
            newArray[index].hNode = childHNode;
            return newArray;
        });
        setAllowedEntries(prev => {
            if (childHNode != null && prev.some(p => p.id === childHNode.id)) {
                const newValues = [...prev];
                newValues.splice(
                    newValues.findIndex(n => n.id === childHNode.id),
                    1
                );
                return newValues;
            }
            return prev;
        });
    }

    if (!ready) {
        return rc(h3, null, 'Loading...');
    }

    const getTreePosition = createTreePositionGetter(hNode.treePosition, entries.length);
    // prettier-ignore
    return rc(StyledDynamicFieldEntry, testProperties(props),
        addAllowed && rc(Header, { key: 'header' },
            rc(Title, null, title?.length > 0 ? title : 'Add a value to update ➟'),
            rc(Button, { ...testProperties(hNode, 'add'), icon: 'add', buttonStyle: 'round', onClick: add })
        ),
        // There seems to be some trouble with the react-reconciler where changing the order of the dom nodes
        // by inserting a new one at the beginning will cause the reconciler to give up and remount the node
        // which means all previous state is lost.  So instead, we use the column-reverse flex direction
        // to order the nodes (with the newest at the top even though it is last in the dom).
        // If you want to see the problem, go to react-dom.development.js and put a breakpoint in the
        // 'updateSlot' method.  You'll see it compares the previous key value using the node that was at that
        // location in the dom with the new key value for the node you inserted.
        rc(ReverseEntries, { key: 'reverse-entries' },
            entries.map((entry, index) => {
                const key = entry.hNode?.id ?? entry.reverseSequence;
                // Ensure any entries that have an hNode also have a tree position
                // designated.
                if (entry.hNode != null) {
                    entry.hNode.treePosition = getTreePosition(index);
                }
                return rc(DynamicFieldEntryRow, {
                    name: 'dynamic-field-entry-row',
                    hNode,
                    entry,
                    allowedEntries,
                    index,
                    remove,
                    entryUsed,
                    key,
                    currentRoute
                });
            })
        )
    );
}

/**
 * Gets the hNodes for all formElements in the data dictionary which are used
 * to write data to the given namespace and relation.
 * @param {string} namespace
 * @param {string} relation
 * @returns [hNodes]
 */
async function getFields(namespace, relation) {
    const dictionary = await metadata.getDictionary();
    const values = omit(dictionary[namespace][relation], '_meta');
    const fields = Object.values(values).map(value => value._meta);
    fields.sort((a, b) => (a.title > b.title ? 1 : -1));
    return fields;
}
