import { useContext, createElement as rc, useEffect, useState, memo } from 'react';
import logging from '@sstdev/lib_logging';
import { View, styled, contexts } from 'lib_ui-primitives';
import { useOrchestrator } from '../hooks';
import HNode from '../HNode';
import setAppName from '../utilities/setAppName';
import useEventSink from '../hooks/useEventSink';
import { constants } from 'lib_ui-services';
import Loading from './Unauthenticated/Loading';
const { SessionContext } = contexts;

const { ASYNC_LOAD_STATUS } = constants;

//specify width on "root" element allows us to use percentages deeper down.
const Root = styled(View).attrs({ name: 'UseCaseRoot' })`
    overflow: hidden;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    height: 100%;
`;

const _p = {
    useOrchestrator,
    setAppName
};

export const _private = _p;

/**
 * Any logic related to the use case lives in this file.
 * When we get here, we are GUARANTEED to have a user with a usecase selected.
 * 1. The basic application orchestration
 * 2. Mobile detection
 * 3. Initiating Theme selection
 * 4. Licence agreement acceptance
 * 5. (Kicking off) Use case rendering (if applicable)
 */
function _UseCaseRoot(props) {
    const { currentRoute } = props || {};
    const [applicationStatus, setAppStatus] = useState('Identifying Use Case.');
    const session = useContext(SessionContext);
    const [, publish] = useEventSink();
    const { status: orchestratorStatus, error: orchestratorError, useCase } = _p.useOrchestrator();

    useEffect(() => {
        if (!session) {
            setAppStatus('No session found.');
            return;
        }
        if (!session.useCase) {
            setAppStatus(
                `Failed to load requested use case for user ${
                    session.profile?.displayName || session.profile?.nickname
                }`
            );
            return;
        }
        if (!session.profile) {
            setAppStatus('No user profile found.');
            return;
        }
        const profile = session.profile;
        const useCaseTitle = session.useCase['metaui:useCase'].title;
        const tenantTitle = session.tenant['identity:tenant'].title;
        switch (orchestratorStatus) {
            case ASYNC_LOAD_STATUS.notStarted:
                setAppStatus('Selecting Use Case.');
                break;
            case ASYNC_LOAD_STATUS.pending: {
                setAppStatus(`Loading ${useCaseTitle} for ${profile.displayName} on ${tenantTitle}.`);
                break;
            }
            case ASYNC_LOAD_STATUS.success: {
                if (!useCase) {
                    setAppStatus(`Unable to load ${useCaseTitle}.`);
                    break;
                }
                setAppStatus(`${useCaseTitle} ready.`);

                // Inform the rest rest of the app that we have a use case UI.
                // This is for stuff like the service worker installer.
                publish(
                    { initialRenderComplete: true },
                    { verb: 'change', namespace: 'application', relation: 'runtime' }
                );
                break;
            }
            default:
                //error...
                setAppStatus('Failed to load use case.');
                break;
        }
    }, [orchestratorStatus, session, useCase, publish]);

    useEffect(() => {
        if (useCase) {
            setAppStatus('Rendering User Interface.');
        }
        _p.setAppName(useCase);
    }, [useCase]);

    useEffect(() => logging.debug('[USECASE] ' + applicationStatus), [applicationStatus]);
    useEffect(() => {
        if (orchestratorError) {
            let error = orchestratorError;
            if (Array.isArray(orchestratorError?.form)) {
                error = orchestratorError.form[0];
            }
            if (error._isUserError) {
                logging.info('[USECASE]', error.stack || error.message || error);
            } else {
                logging.error('[USECASE]', error.stack || error.message || error);
            }
        }
    }, [orchestratorError]);

    // prettier-ignore
    return rc(Root, { name: 'UseCaseRoot' },
        // feedback while loading
        !useCase && rc(Loading, { message: applicationStatus, error: orchestratorError }),
        // User authenticated and authorized, display actual use case
        useCase ? rc(HNode, { key: useCase.hNodes.hNodeType, hNode: useCase.hNodes, currentRoute }) : null
    );
}
const UseCaseRoot = memo(_UseCaseRoot);
export default UseCaseRoot;
