import lodash from 'lodash';
import getTransformedPatches from '../getTransformedPatches';

const { get } = lodash;

export default {
    getFilter,
    getUriComponent,
    fromHNode,
    pageResetRequired: true,
    getAggregateReducer
};

/**
 *
 * @param {{propertyName?:string}} param0
 * @param {{limitedUserContext:object}} contextualInfo
 * @returns
 */
function fromHNode({ propertyName = 'patches' }, contextualInfo = {}) {
    return getFilter(propertyName, contextualInfo);
}

function getFilter(propertyName = '', { limitedUserContext = {}, namespace, relation }) {
    return {
        propertyName,
        appId: limitedUserContext?.useCaseId,
        namespace,
        relation
    };
}

function getUriComponent(filters) {
    const filter = filters['patchDetail'];
    let { propertyName } = filter;

    return `patchDetail=${encodeURIComponent(propertyName)}`;
}

function getAggregateReducer(filters, initialItemCount, dataModel) {
    if (!filters?.patchDetail) {
        return x => x;
    }
    //we need to spread by the fanOut specs, and return a value per each
    const { propertyName, appId } = filters.patchDetail;
    const appIdContext = {
        activeUseCase: {
            'metaui:useCase': {
                _id: appId
            }
        }
    };

    const key = propertyName.split('.');

    if (key.length === 1) {
        // if the key is exactly a single property name
        // we can use a more efficient implementation
        return array => {
            return array.flatMap(record => {
                if (!record) return undefined;

                const values = get(record, key);
                if (!values || !values.length || !Array.isArray(values)) {
                    return record;
                }
                return getTransformedPatches(record._id, values, appIdContext, dataModel).map(transformedPatch => ({
                    ...record,
                    [propertyName]: [transformedPatch]
                }));
            });
        };
    } else {
        throw new Error('Accessing patch details from a nested path is not supported');
    }
}
