import { EXCEPTIONS } from './EXCEPTIONS';
import { pathsToFilter } from './pathsToFilter';
import {
    alwaysAllowUndefinedCriteriaFilter,
    requireMatchAllFilter,
    foreignKeyFilter,
    rootIdFilter
} from './strategies';

const _p = {
    pathsToFilter
};

export const _private = _p;

/**
 * Figure out if for the given role, this relation needs data filtering.
 * @param {string} namespace e.g. 'item'
 * @param {string} relation  e.g. 'transaction'
 * @param {import('../../../../lib_ui-components/src/hooks/useDataModel').DataModel} dataModel the data model for the given namespace/relation
 * @param {import('../../../../lib_ui-components/src/hooks/useProfileRole').Role} role
 */

export default function createDataFilter(namespace, relation, dataModel, role) {
    if (!namespace || !relation || !dataModel || !role) return undefined;

    const dataFilters = Object.entries(role.dataRights).reduce(
        (dataFilters, [key, value]) => {
            if (value?.length) {
                const fixedSpec = EXCEPTIONS[namespace]?.[relation]?.[key];
                const paths = fixedSpec?.paths || _p.pathsToFilter(key, dataModel);
                let filter;

                // figure out what strategy to use for constructing the filter:
                if (`${namespace}:${relation}` === key) {
                    filter = rootIdFilter(paths, value);
                } else if (fixedSpec?.requireMatchAll) {
                    filter = requireMatchAllFilter(paths, value);
                } else if (fixedSpec?.alwaysAllowUndefinedCriteria) {
                    filter = alwaysAllowUndefinedCriteriaFilter(paths, value);
                } else {
                    filter = foreignKeyFilter(paths, value);
                }

                // IF we got a filter, add it to the result set.
                if (filter) {
                    dataFilters.$and.push(filter);
                }
            }
            return dataFilters;
        },
        { $and: [] }
    );

    if (!dataFilters.$and.length) return undefined;
    const result = dataFilters.$and.length === 1 ? dataFilters.$and[0] : dataFilters;
    return result;
}
