import log from '@sstdev/lib_logging';
import { default as globalConfig } from '../globalConfig/index';
import { default as Database } from './Database';
import EventSink from '../eventSink';

function eventSink() {
    return EventSink();
}

let database;
const readiness = {
    setCrashed: undefined,
    setReady: undefined,
    isDbReady: undefined
};
readiness.isDbReady = new Promise((resolve, reject) => {
    readiness.setReady = resolve;
    readiness.setCrashed = reject;
});
export const isDbReady = () => readiness.isDbReady;
const _p = {
    initialize,
    readiness,
    database
};
export default {
    get: getDatabase,
    initialize: InitializeForSession,
    isDbReady,
    _private: _p
};

function getDatabase() {
    return _p.database;
}
export async function InitializeForSession(session, useCaseMetadata) {
    log.debug('[PERSISTENCE] Initializing');

    await initialize({
        config: globalConfig(),
        namespaces: useCaseMetadata.namespaces,
        tenantId: session.tenant['identity:tenant']._id,
        useCaseId: session.useCase['metaui:useCase']._id,
        groupUrlPrefix: `/g/${session.groupNumber}`
    });
    readiness.setReady();
}

async function initialize(payload) {
    const { config, namespaces, tenantId, useCaseId } = payload;
    const [, publish] = eventSink();
    const settings = {
        config,
        namespaces,
        tenantId,
        useCaseId,
        actionPublisher: publish
    };
    _p.database = new Database(settings);

    await _p.database.initializeStorage();
    return _p.database.dispatch([], payload, {
        verb: 'persist',
        namespace: 'application',
        relation: 'useCase',
        type: 'initialize'
    });
}
