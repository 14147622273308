import { useContext, createElement as ce, useEffect, useState, useCallback } from 'react';
import { http, session } from 'lib_ui-services';
import { setupRulesEngine } from 'lib_ui-core';
import { contexts, Button, Card, h2, View, styled, webOnlyStyles, ScrollView } from 'lib_ui-primitives';
import useEventSink from '../../hooks/useEventSink';
import Agreement from './Agreement';
import Logo from '../Logo';
import log from '@sstdev/lib_logging';

let Centered = styled(View).attrs({ name: 'centered' })`
    width: 100%;
    height: 100%;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
`;

Centered = webOnlyStyles(Centered)`
    box-sizing: border-box;
`;

let Main = styled(View).attrs({ name: 'main' })`
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    max-width: 800px;
`;

Main = webOnlyStyles(Main)`
    overflow-y: hidden;
`;

const StickyFooter = styled(View).attrs({ name: 'stickyFooter' })`
    justify-content: center;
    padding: 12px 0px;
    flex-grow: 0;
    flex-basis: auto;
    flex-shrink: 0;
`;

const CardBody = webOnlyStyles(Card.Body)`
    overflow-y: hidden;
`;

const loading = 'Loading...';

export default function LicenseAgreement() {
    //register a handler for when any of the nested forms (all but sign up?) do something with the user
    //we do not have a rules engine nor anything else listening...
    //useAuthentication();
    const eventSink = useEventSink();
    const currentSession = useContext(contexts.SessionContext);
    const { groupNumber } = currentSession;
    useEffect(() => {
        if (!currentSession || !Object.keys(currentSession).length) {
            session.logout();
            return;
        }
        setupRulesEngine(currentSession);
    }, [currentSession]);
    const [, publish] = eventSink;

    const licenseAgreement = getLicenseAgreement(currentSession.useCase);

    useEffect(() => {
        if (!licenseAgreement?._id) session.logout();
    }, [licenseAgreement]);

    const [content, setContent] = useState(loading);

    useEffect(() => {
        if (!licenseAgreement?._id) return;
        if (licenseAgreement.content) setContent(licenseAgreement.content);
        let allowStateUpdate = true;
        const doAsync = async () => {
            try {
                const {
                    items: [agreement]
                } = await http.get(
                    `/g/${groupNumber}/api/backbone/${licenseAgreement.type.toLowerCase()}/${licenseAgreement._id}`,
                    true
                );
                if (allowStateUpdate) {
                    setContent(agreement.content);
                }
            } catch (error) {
                log.error(error);
            }
        };
        doAsync();
        return () => (allowStateUpdate = false);
    }, [licenseAgreement, groupNumber]);

    /**
     * @param {import('react').MouseEvent<Button>} event
     */
    // eslint-disable-next-line no-unused-vars
    function onReject(event) {
        publish({}, { verb: 'remove', namespace: 'security', relation: 'profile' });
    }

    /**
     * @param {import('react').MouseEvent<Button>} event
     */
    // eslint-disable-next-line no-unused-vars
    const onAccept = useCallback(() => {
        publish(
            {
                acceptedLicense: licenseAgreement
            },
            { verb: 'update', namespace: 'security', relation: 'profile', type: 'acceptLicense' }
        );
    }, [licenseAgreement, publish]);

    if (!licenseAgreement) return null;

    // prettier-ignore
    return ce(Centered, null,
        ce(Main, null,
            ce(Card, { style: { height: '100%' } },
                ce(Card.Header, null, ce(h2, null, licenseAgreement.displayTitle)),
                ce(CardBody, null,
                    ce(ScrollView, { id: 'license-agreement-container' },
                        ce(Agreement, { html: content })
                    )
                ),
                ce(Card.Footer, { style: { justifyContent: 'flex-end' } },
                    ce(Button, { value: 'I DISAGREE', buttonStyle: 'error', onClick: onReject }),
                    ce(Button, { value: 'I AGREE', buttonStyle: 'success', onClick: onAccept })
                )
            )
        ),
        ce(StickyFooter, null, ce(Logo))
    );
}

/**
 * @param {import('../../../../types').useCase} useCase
 */
function getLicenseAgreement(useCase) {
    if (useCase?.needMlaAcceptance)
        return { ...useCase['backbone:mla'], type: 'MLA', displayTitle: 'Master License Agreement' };

    if (useCase?.needEulaAcceptance)
        return { ...useCase['backbone:eula'], type: 'EULA', displayTitle: 'End User License Agreement' };

    return false;
}
