import constants from 'lib_ui-services/src/constants';

/**
 *
 * @param {{
 *    tagId:string,
 *    sensorType:string,
 *    rssi?:number,
 *    inDatabase?:boolean,
 * }} newEntry
 * @param {Array} dbResults
 * @param {{
 *    displayGatheredErrors:function,
 *    titularField:string,
 *    publish:function,
 *    request:function,
 *    matchProperties:Array<string>,
 *    displayInAscii:boolean,
 *    dataRights:import('../../../hooks/useProfileRole').Role
 *    mergeNamespace:string,
 *    mergeRelation:string,
 *    unknownTagAction:constants.tagAction,
 *    inactiveTagAction:constants.tagAction,
 *    beepForNewReads:boolean
 * }} config

* @param {function} update unused
* @param {function} remove
* @returns {Promise<boolean>} True if the grid entry was updated, false if it was removed
 */
async function merge(newEntry, dbResults, config, update, remove) {
    const hexLookup = [constants.sensorTypes.RFID, constants.sensorTypes.BLE].includes(newEntry.sensorType);

    if (hexLookup) {
        throw new Error(
            `Unexpected number of database entries (${dbResults.length}) found for tagId ${newEntry.tagId}`
        );
    } else {
        const message = `More than one record matches ${newEntry.tagId}.`;
        config.displayGatheredErrors(message);
        remove(newEntry);
    }
    return false;
}

export default { merge };
