import { constants, metadata } from 'lib_ui-services';
import getLocationInfo from '../getLocationInfo';
import getAllFeatureFlags from '../../../../../utilities/getAllFeatureFlags';

const { useCaseIds } = constants;

export default {
    verb: 'willNew',
    namespace: 'item',
    relation: 'item',
    description: 'Capitalize and prefix with 00s if necessary',
    useCaseIds: [useCaseIds.FLAIR],
    //this is the actual logic:
    logic: willNew
};
const _p = {
    getLocationInfo
};
export const _private = _p;
function willNew({ data, context }) {
    const featureFlags = getAllFeatureFlags(context);

    const titleAlternative = metadata.getTitleAlternative(context.namespace, context.relation, 'title');
    let passedInItemId = data?.newRecord?.[titleAlternative];
    if (passedInItemId) {
        if (featureFlags.includes('forFOG')) {
            if (passedInItemId.length < 6) {
                passedInItemId = passedInItemId.padStart(6, '0');
            }
        } else {
            if (passedInItemId.length < 8) {
                passedInItemId = passedInItemId.padStart(8, '0');
            }
        }
        data.newRecord[titleAlternative] = passedInItemId.toUpperCase();
    }

    return data;
}
