// import * as persistenceWorker from './persistenceWorker';
import { default as setupRulesEngine } from './setupRulesEngine';
import {
    database,
    localStorage,
    sessionStorage,
    metadata,
    loadUseCase,
    network,
    offlineResilientCommunication,
    registerServiceWorker,
    socket,
    constants
} from 'lib_ui-services';
import log from '@sstdev/lib_logging';
import enableDevtoolCommands from './devToolCommands';
import { eventSink as EventSink } from 'lib_ui-services';
let unsubscribes = [];

function eventSink() {
    return EventSink();
}
/**
 *
 * @param {object} param
 * @param {object} param.user
 * @param {import('lib_ui-services/src/eventSink').EventSink} param.eventSink [ subscribe, publish, request ]
 */
export default async function orchestrateUseCase(session) {
    /*
     * #5992. Drop down selections from one tenant were being loaded after user switched to another tenant
     * Added a prefix to local storage keys to prevent cross talk between tenants.
     */
    const prefix = session.tenant['identity:tenant']._id + '_'; //underscore is just there to visually separate the prefix
    log.debug(`[ORCHESTRATOR] Setting local storage prefix: ${prefix}`);
    sessionStorage.init();
    localStorage.setPrefix(prefix);
    log.debug(`[ORCHESTRATOR] Getting use case ${session.useCase['metaui:useCase'].title}`);
    // get use case. We have to do this directly,
    // as the persistence worker currently requires the use case in order to be initialized.

    const payload = {
        query: {
            _id: session.useCase['metaui:useCase']._id,
            role: session.role || { title: 'USER', _id: constants.WELL_KNOWN_ROLE_IDS.USER },
            featureFlags: session.allFeatureFlags || []
        }
    };

    const useCaseMetadata = await loadUseCase(payload);
    metadata.setMetadata(useCaseMetadata);
    log.debug('[ORCHESTRATOR] Registering the service worker');
    //Enable service worker for offline support (noop for native)
    registerServiceWorker.registerMainServiceWorker(eventSink());
    log.debug('[ORCHESTRATOR] Wiring up offline resilient communication to event sink for the current use case');
    await offlineResilientCommunication.initialize(eventSink());
    log.debug('[ORCHESTRATOR] Wiring up persistence to event sink for the current use case');
    await database.initialize(session, useCaseMetadata);
    await socket.connect(eventSink());

    log.debug('[ORCHESTRATOR] Wiring up rules engine to event sink for the current use case');
    // connect rules engine to appropriate rules
    setupRulesEngine(session);

    unsubscribes.forEach(unsubscribe => unsubscribe());
    unsubscribes = [network.listenForChange(eventSink())];

    enableDevtoolCommands(eventSink());

    return {
        useCase: useCaseMetadata,
        synchronizeData: async () => {
            log.debug('[ORCHESTRATOR] Synchronizing initial data');
            const [, , request] = eventSink();

            log.debug('[ORCHESTRATOR] Initiating full sync');
            const timeOut = 0; //0 disables timeout.
            const result = await request(
                {},
                { verb: 'update', namespace: 'application', relation: 'useCase', type: 'syncAllDataToLocal' },
                timeOut
            );
            return result;
        }
    };
}

export const orchestrator = {
    orchestrateUseCase
};
