import { ObjectId } from 'lib_ui-services';
import getShortProfileForMeta from '../../../utilities/getShortProfileForMeta';
export default {
    verb: 'willRemove',
    namespace: 'application',
    relation: 'notification',
    prerequisites: [],
    description: 'Prepare to remove the given record on the database',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic({ data, context }) {
    const { _id: id } = data;
    const currentTime = new Date().toISOString();
    const currentUser = getShortProfileForMeta(context);
    context.correlationId = context.correlationId || new ObjectId().toString();
    const meta = { deleted: true, deletedBy: currentUser, deletedTime: currentTime };
    return { id, meta };
}
