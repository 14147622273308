import { network, http, session } from 'lib_ui-services';

export default {
    verb: 'doingUpdate',
    namespace: 'security',
    relation: 'profile',
    type: 'acceptLicense',
    prerequisites: [],
    description: 'Accept a license when logging in',
    //this is the actual logic:
    logic: acceptLicense
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function acceptLicense({ data, context }) {
    const networkStatus = await network.getStatus();
    const { acceptedLicense, groupNumber } = data;
    if (networkStatus.isOnline) {
        if (acceptedLicense != null) {
            await sendLicenseAcceptance(acceptedLicense, groupNumber, context);
            await session.setLicenseAccepted(acceptedLicense);
        }
    } else {
        throw new Error('Please go online to accept the license.');
    }
}

const _p = {
    getNetworkStatus: network.getStatus,
    httpPatch: http.patch
};
export const _private = _p;

async function sendLicenseAcceptance(acceptedLicense, groupNumber = 0, context) {
    const networkStatus = await _p.getNetworkStatus();
    if (networkStatus.isServerReachable) {
        const {
            session: { profile, tenant, useCase }
        } = context; //await _p.getCurrentSession();
        const userId = profile._id ?? profile.userId;
        let patchValue = {
            'identity:tenant': tenant['identity:tenant'],
            'metaui:useCase': useCase['metaui:useCase'],
            acceptedAt: { $date: new Date().toISOString() }
        };
        let path;

        if (acceptedLicense.type === 'MLA') {
            patchValue['backbone:mla'] = {
                _id: acceptedLicense._id,
                title: acceptedLicense.title
            };
            path = '/acceptedMla/-';
        } else if (acceptedLicense.type === 'EULA') {
            patchValue['backbone:eula'] = {
                _id: acceptedLicense._id,
                title: acceptedLicense.title
            };
            path = '/acceptedEula/-';
        }

        const patch = [
            {
                op: 'add',
                path,
                value: patchValue
            }
        ];
        await _p.httpPatch(`/g/${groupNumber}/api/identity/self/${userId}`, patch);
    } else {
        throw new Error('Please go online to accept License.');
    }
}
