import { createElement as rc, useState, useEffect, useCallback } from 'react';
import { Spinner, View, fromTheme, styled, hooks, h2, h3, h4, Button, testProperties } from 'lib_ui-primitives';
import Logo, { variants } from '../Logo';

import { globalConfig, network } from 'lib_ui-services';
const useTimeout = hooks.useTimeout;

const CacheClearingContainer = styled(View).attrs({ name: 'cache-clearing-container' })`
    flex-direction: column;
`;
CacheClearingContainer.displayName = 'CacheClearingContainer';
const ButtonContainer = styled(View).attrs({ name: 'button-container' })`
    flex-direction: row;
    justify-content: center;
`;
ButtonContainer.displayName = 'ButtonContainer';

const SessionError = styled(h3).attrs({ name: 'session-error' })`
    color: ${fromTheme('errorFontColor')};
`;
SessionError.displayName = 'SessionError';

const LoadBackground = styled(View).attrs({ name: 'load-background' })`
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: ${fromTheme('baseBackgroundColor')};
`;
LoadBackground.displayName = 'LoadBackground';

export default function Loading(props) {
    const { message, error } = props;
    const [tryClearingCache, setTryClearingCache] = useState(false);
    const waitAndSuggestCacheClear = useTimeout(
        () => {
            setTryClearingCache(true);
        },
        [],
        globalConfig.loadingTimeout ?? 15000
    );

    // Call initial timeout for cache clearing suggestion
    useEffect(() => {
        waitAndSuggestCacheClear();
    }, [waitAndSuggestCacheClear]);

    const noDoNotClearCache = useCallback(() => {
        setTryClearingCache(false);
        waitAndSuggestCacheClear();
    }, [waitAndSuggestCacheClear]);

    const yesDoClearCache = useCallback(() => {
        network.clearCache();
    }, []);

    // prettier-ignore
    return rc(LoadBackground, { 'data-testid': 'load-background' },
        rc(Logo, { variant: variants.COLOR }),
        message && rc(h2, testProperties(null, 'load-message'), message),
        (error == null) && rc(Spinner, { size: 'large' }),
        error && rc(SessionError, testProperties(null, 'load-error-message'), error.message),
        tryClearingCache &&
            rc(CacheClearingContainer, null,
                rc(h4, testProperties(null, 'load-cache-clearing-message'),
                    'There seems to be a problem.  Would you like to try clearing local data and restarting fresh with data from the server?'
                ),
                rc(ButtonContainer, null,
                    rc(Button, { onClick: yesDoClearCache, ...testProperties(null, 'loading-clear-cache-button-yes') }, 'Yes'),
                    rc(Button, { onClick: noDoNotClearCache, ...testProperties(null, 'loading-clear-cache-button-no') }, 'No')
                )
            )
    );
}
