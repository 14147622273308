// move this to the root to avoid circular dependencies
import constants from '../constants';
import http from '../http';
import localStorage from '../localStorage';
import * as network from '../network';
import adjustAvailableControlsByFeatureFlags from './adjustAvailableControlsByFeatureFlags';
import adjustAvailableControlsByHiddenFlag from './adjustAvailableControlsByHiddenFlag';
import adjustAvailableControlsByPlatform from './adjustAvailableControlsByPlatform';
import logging from '@sstdev/lib_logging';
import { setDictionary } from '../metadata';
import metadataServices from '@sstdev/lib_metadata-services';
const { dataDictionaryService } = metadataServices;

// Make these easier to mock in tests.
const _p = {
    adjustAvailableControlsByFeatureFlags,
    adjustAvailableControlsByHiddenFlag,
    adjustAvailableControlsByPlatform
};

export default loadUseCase;
export const _private = _p;

async function loadUseCase(action) {
    const {
        query: { _id, role, featureFlags }
    } = action;
    if (!_id) {
        throw new Error('There is a problem starting the application configuration.');
    }
    // Get the hash of cached use cases.
    const useCaseHash = await localStorage.getKey('useCaseHash', undefined, {}, false);
    const networkStatus = await network.getStatus();
    if (!networkStatus.isOnline) {
        return handleOffline(useCaseHash, _id, role);
    } else {
        // Get the full use case for this user's active use case id from the server
        // Do this every time in case something has changed.
        // Without cache-control, okhttp on react-native tries to send if-modified-since :(
        let useCase;
        try {
            useCase = await http.get(`/api/metaui/useCase/${_id}`, {
                'Cache-Control': 'no-cache'
            });
        } catch (err) {
            if (err.message === 'Failed to fetch') {
                return handleOffline(useCaseHash, _id, role);
            }
            throw err;
        }
        // Accommodate both legacy and current result formats.
        if (Array.isArray(useCase)) {
            useCase = useCase[0];
        } else {
            useCase = useCase?.items?.[0];
        }
        if (useCase == null) {
            throw new Error(`The request for use case (_id ${_id}) returned an unexpected format.`);
        }

        const { title, namespaces, topMenu, hNodes } = useCase;
        if (title == null) {
            throw new Error(`The use case retrieved (for _id ${_id}) did not have a title.`);
        }
        if (namespaces == null) {
            throw new Error(`The ${title} use case is missing namespaces.`);
        }
        if (topMenu == null) {
            throw new Error(`The ${title} use case is missing a top menu.`);
        }
        if (hNodes == null) {
            throw new Error(`The ${title} use case is missing UI construction information (e.g. hNodes).`);
        }

        // Get dictionary before adjusting available controls.
        let _accessRole = role;
        // unless the role is a system role, treat is as an ADMIN for maximum access
        if (!Object.keys(constants.WELL_KNOWN_ROLES).includes(role)) {
            _accessRole = 'ADMIN';
        }
        const dataDictionary = dataDictionaryService.transform(useCase, _accessRole, (...args) =>
            logging.warn.call(logging, ...args)
        );
        await setDictionary(dataDictionary);

        _p.adjustAvailableControlsByFeatureFlags(useCase.topMenu, featureFlags);
        _p.adjustAvailableControlsByFeatureFlags(useCase.hNodes, featureFlags);
        // Most of the role-hidden features should already have been filtered out by the server.
        // but just in case.
        _p.adjustAvailableControlsByHiddenFlag(useCase.topMenu, _accessRole.toLowerCase());
        _p.adjustAvailableControlsByHiddenFlag(useCase.hNodes, _accessRole.toLowerCase());
        _p.adjustAvailableControlsByPlatform(useCase.topMenu);
        _p.adjustAvailableControlsByPlatform(useCase.hNodes);

        // Insert the latest version of the full use case record into the
        // use case hash in local storage (for offline logins)
        useCaseHash[`${_id}-${role._id}`] = useCase;
        await localStorage.setKey('useCaseHash', useCaseHash, undefined, false);
        return useCase;
    }
}
function handleOffline(useCaseHash, _id, role) {
    // If offline, use the cached version of the full used case record
    if (!useCaseHash[`${_id}-${role._id}`] && !useCaseHash[_id]) {
        throw new Error(
            'This device appears to be offline, and it does not appear that you have logged into the application on this device recently.  You will need to regain internet connectivity before you can log in.'
        );
    }
    return useCaseHash[`${_id}-${role._id}`] || useCaseHash[_id];
}
