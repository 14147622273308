import logging from '@sstdev/lib_logging';
import { filters, metadata } from 'lib_ui-services';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';

export default {
    verb: 'doingFind',
    namespace: 'item',
    relation: 'item',
    description: 'Override find event to create or update the given record on the database',
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'item',
                relation: 'item',
                type: 'find'
            },
            query: ({ data }) => {
                const { searchText } = data;
                return {
                    $or: [{ assetNo: searchText }, { serialNo: searchText }],
                    'meta.deleted': { $exists: false }
                };
            }
        },
        {
            context: { verb: 'get', namespace: 'identity', relation: 'userRole' }
        }
    ],
    //this is the actual logic:
    logic: editOrCreateForLookup
};

const _p = {
    checkAccess
};
export const _private = _p;

/**
 * @param {Object} hNode
 * @param {string} searchText
 * @param {boolean} [forceOnline=true]
 */
async function editOrCreateForLookup({ data: { hNode, searchText }, prerequisiteResults, context, dispatch }) {
    let { relation, namespace, foundOneAction, noneFoundAction, propertyName = 'title' } = hNode;
    const prerequisiteResult = prerequisiteResults[0];
    if (prerequisiteResult.error != null) {
        throw new Error(prerequisiteResult.error);
    }
    const records = prerequisiteResult?.result ?? [];
    const userRole = prerequisiteResults[1]?.result?.[0];
    const clientDataRights = getAllFeatureFlags(context).includes('clientDataRights') ?? false;

    try {
        if (records && records.length > 0) {
            let record = records[0];
            if (clientDataRights && !_p.checkAccess(record, userRole)) {
                logging.info(`User does not have access to record ${record.assetNo}`, context);
                return;
            }

            dispatch(
                { _id: record._id, record },
                {
                    ...context,
                    verb: foundOneAction,
                    namespace,
                    relation
                }
            );
        } else {
            await dispatch(
                {
                    newRecord: {},
                    createdBySearch: true
                },
                {
                    ...context,
                    verb: noneFoundAction,
                    namespace,
                    relation
                },
                {
                    awaitResult: true,
                    waitForSubscriber: true
                }
            );
            // Dispatch this change separately so that record will be dirty
            setTimeout(
                () =>
                    dispatch(
                        {
                            propertyName,
                            newValue: searchText
                        },
                        { verb: 'beginChange', namespace, relation },
                        { waitForSubscriber: true }
                    ),
                0
            );
        }
    } catch (err) {
        const message = 'There was a problem looking up or creating this item';
        logging.error(message, err, context);
        dispatch({ message }, { verb: 'pop', namespace: 'application', relation: 'notification' });
    }
}

async function checkAccess(item, userRole) {
    const dataDictionary = await metadata.getDictionary();
    const dataModel = dataDictionary?.item?.item;
    const filterCriteria = filters.createDataFilter('item', 'item', dataModel, userRole);

    const hasAccess = filters.filterHelpers.criteriaToJavaScript(filterCriteria);
    return hasAccess(item);
}
