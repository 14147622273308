import { ObjectId } from 'lib_ui-services';
import lodash from 'lodash';
import iso from '@sstdev/lib_isomorphic-json-patch';
import getShortProfileForMeta from '../../utilities/getShortProfileForMeta';
const { cloneDeep } = lodash;
const { merge } = lodash;
const { set } = lodash;
// Must destructure here because @sstdev/lib_isomorphic-json-patch is commonjs
const { rfc6902 } = iso;

export default {
    verb: 'doingCli',
    namespace: 'item',
    relation: 'item',
    description: 'Create new test item, or update test item',
    //this is the actual logic:
    logic
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function logic(parameters) {
    if (parameters.data.type === 'create') {
        return testNewAsset(parameters);
    }
    if (parameters.data.type === 'create') {
        return testUpdatedAsset(parameters);
    }
}

async function testUpdatedAsset({ data, context, dispatch, log }) {
    const shortProfile = getShortProfileForMeta(context);

    const response = await dispatch(
        {
            type: 'find_item_item',
            find: {
                criteria: data.criteria
            }
        },
        true
    );
    if (!response || response.result.length === 0) {
        log.error(`Could not find an asset with assetNo ${data.criteria.assetNo}.`);
    }
    const oldModel = response.result[0];
    const newModel = cloneDeep(oldModel);
    merge(newModel, data.changes);
    delete newModel.serverModifiedTime;

    // Just to be sure
    set(newModel, 'inventory[0].found', data.changes.inventory?.[0].found ?? true);
    set(newModel, 'inventory[0].foundBy', data.changes.inventory?.[0].foundBy ?? shortProfile);
    newModel.meta.modifiedBy = data.changes.meta?.modifiedBy ?? shortProfile;
    newModel.meta.modifiedTime = data.changes.meta?.modifiedTime ?? new Date().toISOString();

    const patch = rfc6902.compare(oldModel, newModel, true);
    const result = await dispatch(
        {
            type: 'update_item_item',
            update: {
                _id: newModel._id,
                patch,
                oldModel,
                newModel
            }
        },
        true
    );
    log.info('-'.repeat(50));
    log.info(result);
}

async function testNewAsset({ data, context, dispatch, log }) {
    const asset = data.payload;
    if (asset?.assetNo == null) {
        log.error('You must, at a minimum, specify assetNo like so: {assetNo: "<some asset number>"}');
    }

    const shortProfile = getShortProfileForMeta(context);

    const response = await dispatch({
        type: 'find_inventory_inventory',
        find: {
            criteria: { closedTime: { $exists: false } }
        }
    });
    if (!response || response.result.length === 0) {
        log.error('Could not find an open inventory.');
    }
    const { _id: inventoryId, title } = response.result[0];
    let newModel = cloneDeep(template);
    newModel._id = new ObjectId().toString();
    merge(newModel, asset);
    delete newModel.tenantId;

    // Just to be sure
    set(
        newModel,
        'inventory[0].inventory:inventory',
        asset.inventory?.[0]['inventory:inventory'] ?? { _id: inventoryId, title }
    );
    set(newModel, 'inventory[0].found', asset.inventory?.[0].found ?? true);
    set(newModel, 'inventory[0].foundBy', asset.inventory?.[0].foundBy ?? shortProfile);
    newModel.meta.modifiedBy = asset.meta?.modifiedBy ?? shortProfile;
    newModel.meta.modifiedTime = asset.meta?.modifiedTime ?? new Date().toISOString();

    log.info(newModel);
    const result = await dispatch({
        type: 'insert_item_item',
        insert: {
            newModel
        }
    });
    log.info('-'.repeat(50));
    log.info(result);
}

const template = {
    _id: ObjectId('603e5fd1bfa261fce7e550c5'),
    assetNo: '00152419',
    tenantId: ObjectId('5ecd6c08d88406e786b0ba8a'),
    description: 'Some Test thing',
    inventory: [
        {
            'inventory:inventory': {
                _id: ObjectId('603e5fd0e2f6b79cabfbafee'),
                title: 'FY21 Annual Property Inventory'
            },
            foundBy: {
                _id: ObjectId('60425f7c163da1dab27d1883'),
                displayName: 'Jessica  Chui'
            },
            foundDate: '2021-03-22T17:59:52.257Z',
            isNew: true
        }
    ],
    'item:condition': {
        title: '2 - Good',
        _id: ObjectId('32676f6f64436f6e64697469')
    },
    'location:location': {
        title: '3701',
        _id: ObjectId('603e5fd1bfa261fce7e54e5d')
    },
    meta: {
        createdBy: {
            _id: ObjectId('5f88f1376c1b284cd223bc27'),
            displayName: 'Test Entry'
        },
        createdTime: '2021-03-02T15:54:57.345Z',
        modifiedBy: {
            _id: ObjectId('60425f7c163da1dab27d1883'),
            displayName: 'Test Entry'
        },
        modifiedTime: '2021-03-22T17:59:58.767Z'
    },
    'organization:organization': {
        title: '010101001',
        _id: ObjectId('603e5fd1bfa261fce7e54e50')
    },
    serialNo: 'R52J50FH04V'
};
