/**
 * The Zxing camera scanner library is very large.  This will lazy load it.
 */
import { lazy, createElement as rc, Suspense } from 'react';
import { Text } from 'lib_ui-primitives';

// This allows the barcode dependencies to be loaded lazily, which in turn allows webpack
// to put them in a separate chunk.  This helps initial page load time because these big
// dependencies don't have to be loaded until they are needed.
export default function BarcodeScannerProxy(props) {
    const BarcodeScanner = lazy(() => import(/* webpackPrefetch: true */ './BarcodeScannerInner'));
    const fallback = rc(Text, null, 'Loading...');
    // prettier-ignore
    return rc(Suspense, {fallback},
        rc(BarcodeScanner, props)
    );
}
