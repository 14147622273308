import { ObjectId, offlineResilientCommunication, metadata, constants } from 'lib_ui-services';
import getShortProfileForMeta from '../../../../../utilities/getShortProfileForMeta';

const { notificationTypes } = constants;

export default {
    verb: 'doingBulk',
    description: 'Fan out the selection of a mass delete operation to do all actual removes',
    type: 'remove',
    logic: doingBulkRemove,
    onError
};

const _p = {
    getOfflineAction: offlineResilientCommunication.getOfflineAction,
    metadata
};
export const _private = _p;

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: {selection:Array<{_id:string}>};
 *   prerequisiteResults: Array<{result:Array<object>}>;
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function doingBulkRemove({ data, dispatch, context, log }) {
    if (!data?.selection?.length) return;
    const { type, namespace, relation } = context;
    const meta = createMetadata(context);

    const records = data.selection;
    const totalRecords = records.length;
    const prettyName = _p.metadata.getPrettyRelationName(namespace, relation);
    log.info(`Removing ${totalRecords} ${prettyName} records.`);
    toggleSpinner(dispatch, true);

    for (const { _id: id } of records) {
        const singleContext = { ...context, correlationId: ObjectId() };
        const offlineAction = _p.getOfflineAction(
            meta,
            singleContext.correlationId,
            id,
            { id },
            { ...singleContext, verb: type }
        );
        dispatch(
            {
                id, //databaseOperations/remove.js expects an `id` instead of `_id`
                meta,
                offlineAction,
                skipConfirm: true
            },
            { verb: type, namespace, relation }
        );
    }
    toggleSpinner(dispatch, false);
}

function onError({ error, dispatch }) {
    toggleSpinner(dispatch, false);
    throw error;
}

/**
 * Utility functions
 */
function createMetadata(context) {
    return {
        deleted: true,
        deletedBy: getShortProfileForMeta(context),
        deletedTime: new Date().toISOString()
    };
}

function toggleSpinner(dispatch, isSpinning) {
    const message = isSpinning ? 'Removing' : 'Removal Complete';
    dispatch(
        { busy: isSpinning, source: 'httpSync', message, type: notificationTypes.SYNC_BUSY },
        { verb: 'pop', namespace: 'application', relation: 'notification' }
    );
}
