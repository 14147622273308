import { useMemo, createElement as rc, useRef, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import logging from '@sstdev/lib_logging';
import debouncePromise from 'debounce-promise';
import lodash from 'lodash';
const { set } = lodash;

import { ScrollView, h4, styled, fromTheme, contexts, hooks } from 'lib_ui-primitives';
import { metadata, globalConfig } from 'lib_ui-services';

import FilterInterdependencyBoundary from '../contextProviders/FilterInterdependencyBoundary';
import useDbView from '../../hooks/useDbView';
import useNetwork from '../../hooks/useNetwork';
import ActiveRecord from '../contextProviders/ActiveRecord';
import LoadingBoundary from '../contextProviders/LoadingBoundary';
import HNode from '../../HNode';
import createTreePositionGetter from '../../utilities/createTreePositionGetter';
import SearchPaneHeader from './SearchPaneHeader';

const { useRouter } = hooks;
const { getRelationMetadata } = metadata;

const _SearchPane = styled(ScrollView).attrs({ name: 'search-pane' })`
    background-color: ${({ theme, horizontalLayout }) => (horizontalLayout ? theme.backgroundColor : 'transparent')};
    height: 100%;
    width: ${props => (props.horizontalLayout ? '100%' : '300px')};
    flex-direction: ${props => (props.horizontalLayout ? 'row' : 'column')};
    flex-wrap: ${props => (props.horizontalLayout ? 'wrap' : 'nowrap')};
    flex-basis: auto;
    padding-left: ${fromTheme('viewPadding')};
    border-right: ${fromTheme('backgroundColorDarker')} thin solid;
`;

const SearchPaneTitle = styled(h4)`
    margin: 6px;
    color: ${({ theme }) => theme.defaultFontColor};
`;

const _p = {
    useDbView,
    getRelationMetadata,
    useNetwork,
    getRecalcMethod,
    globalConfig
};
export const _private = _p;
/**
 * @typedef {Object} Props
 * @property {Object} hNode
 * @property {string} currentRoute
 */
/** @type {import('react').FC<Props>} */
function SearchPane(props) {
    const {
        hNode,
        hNode: {
            id,
            namespace,
            relation,
            title,
            verticalLayout,
            horizontalLayout = !verticalLayout && !title,
            children: childHNodes = [],
            treePosition
        },
        testChild
    } = props || { hNode: {} };
    const childFilters = useRef({});
    const pendingRecalc = useRef(false);
    const searchCleanups = useRef([]);
    const { isOnline } = _p.useNetwork();
    const router = useRouter();
    const routePath = router.getRouteStateKey();

    // SearchPane typically will filter a list type control (e.g. Grid)
    // that needs a lokijs dynamicView and corresponding viewCriteria.
    // This will get that viewCriteria.
    const { viewCriteria, viewReady } = _p.useDbView(namespace, relation, undefined, hNode, undefined, false);
    // Avoid getting a new ref for this everytime.
    const recalcFilters = useMemo(
        () => _p.getRecalcMethod(viewCriteria, id, isOnline, namespace, relation, routePath),
        [viewCriteria, id, isOnline, namespace, relation, routePath]
    );

    // If the route changes, then force a recalc so that filters specific to the previous
    // route will be removed.
    useEffect(() => {
        if (viewReady) {
            recalcFilters(childFilters.current[routePath] ?? {}).then(cleanup => {
                if (cleanup != null) {
                    searchCleanups.current.push(cleanup);
                }
            });
        } else {
            pendingRecalc.current = true;
        }
        // This should only be executed if routePath changes (and recalcFilters will
        // be changed if that happens)
        // eslint-disable-next-line
    }, [recalcFilters]);

    // This valueChanged callback is passed down into filtering controls
    // like DropDownSearch.
    // It will recalculate the filters for the list viewCriteria here based on
    // selections in those filtering controls.
    const valueChanged = useCallback(
        (childHNode, filter) => {
            if (childHNode == null || childHNode.hNodeType == null) {
                throw new Error(
                    'A searchElement must fire a valueChange with an hNode as the first argument and the value as the second'
                );
            }
            logging.debug(
                `[SEARCH_PANE] valueChanged ${JSON.stringify(
                    {
                        routePath,
                        child: { id: childHNode.id, titular: childHNode.title ?? childHNode.assetNo },
                        filter
                    },
                    null,
                    3
                )}`
            );
            set(childFilters.current, [routePath, childHNode.id], { hNode: childHNode, filter });
            // Defer the recalc (see below) if the dynamicView isn't ready yet
            if (viewReady) {
                recalcFilters(childFilters.current[routePath] ?? {}).then(cleanup => {
                    if (cleanup != null) {
                        searchCleanups.current.push(cleanup);
                    }
                });
            } else {
                pendingRecalc.current = true;
            }
        },
        [viewReady, recalcFilters, routePath]
    );

    useEffect(() => {
        if (viewReady && pendingRecalc.current) {
            try {
                recalcFilters(childFilters.current[routePath] ?? {}).then(cleanup => {
                    if (cleanup != null) {
                        searchCleanups.current.push(cleanup);
                    }
                });
            } catch (err) {
                logging.error(err);
            } finally {
                pendingRecalc.current = false;
            }
        }
    }, [viewReady, recalcFilters, routePath]);

    useEffect(() => {
        const _searchCleanups = searchCleanups.current;
        return () => {
            _searchCleanups.forEach(cleanup => cleanup());
        };
    }, []);

    const [iteration, forceRender] = useReducer(s => s + 1, 0);

    const resetFilter = () => {
        childFilters.current = {};
    };

    const searchElementChildren = useMemo(
        () => childHNodes.filter(child => child.hNodeTypeGroup === 'searchElement'),
        [childHNodes]
    );
    const searchElementIds = useMemo(() => getChildIds(searchElementChildren), [searchElementChildren]);

    const otherChildren = useMemo(
        () => childHNodes.filter(child => child.hNodeTypeGroup !== 'searchElement'),
        [childHNodes]
    );
    const addHeader = !!otherChildren.length;

    const getTreePosition = createTreePositionGetter(treePosition, searchElementChildren.length);

    // prettier-ignore
    return rc(FilterInterdependencyBoundary, { name: `search-pane-${id}`, restoreSelectionsOnRemount: true },
        rc(contexts.SearchContext.Provider, { value: valueChanged },
            rc(_SearchPane, { ...props, id, horizontalLayout },
                title && title.length ? rc(SearchPaneTitle, { name: 'search-pane-title' }, title) : null,
                addHeader && rc(LoadingBoundary, null,
                    rc(ActiveRecord, { namespace: 'application', relation: 'savedFilter', isNew: true },
                        rc(SearchPaneHeader,{
                            ...props,
                            hNode:{...props.hNode, children:otherChildren},
                            searchElementIds,
                            onLoad:forceRender,
                            resetFilter,
                            currentRoute: routePath
                        })
                    )
                ),
                ...searchElementChildren.map((hNode, index) => {
                    hNode.treePosition = getTreePosition(index);
                    return rc(HNode, { hNode, key: `${index}-${iteration}`, currentRoute:routePath });
                }),
                //just for unit testing:
                testChild
            )
        )
    );
}

SearchPane.defaultProps = {};

SearchPane.propTypes = {
    hNode: PropTypes.object.isRequired,
    currentRoute: PropTypes.string.isRequired
};

export default SearchPane;

function getRecalcMethod(viewCriteria, filterId, isOnline, namespace, relation, routePath) {
    return debouncePromise(
        async childFilterValues => {
            const filters = {};
            Object.values(childFilterValues).forEach(({ filter }) => {
                filters.searchCriteria = { ...filters.searchCriteria, ...filter };
            });

            // If switching between routes, remove any filters associated with a previous
            // route.
            viewCriteria.removeFiltersFromOtherRoutes(routePath);
            viewCriteria.applyFilters(filters, filterId, routePath);
        },
        _p.globalConfig().onlineSearchDebounceMilliseconds,
        { accumulate: false }
    );
}

function getChildIds(root) {
    if (Array.isArray(root)) {
        return root.flatMap(getChildIds);
    }
    const { id, hNodeType, propertyName, children } = root;
    const ownIds = hNodeType === 'Range' ? [`from-${propertyName}-${id}`, `to-${propertyName}-${id}`] : [root.id];
    if (!children || !children.length) return ownIds;
    return [...ownIds, ...children.flatMap(child => getChildIds(child))];
}
