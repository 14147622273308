import { constants, http } from 'lib_ui-services';
const { useCaseIds } = constants;
export const _private = { post: http.post };
export default {
    verb: 'doingCreate',
    namespace: 'deploy',
    relation: 'environment',
    type: 'singleRecordForm',
    prerequisites: [],
    excludedUseCaseIds: [useCaseIds.ONE_TOUCH],
    description: 'Used to create a haven (as opposed to a normal environment)',
    //this is the actual logic:
    logic: createHaven
};

/**
 * @typedef {import("rulesengine.io").LoggingProvider} LoggingProvider
 * @typedef {import("rulesengine.io").WorkflowStack} WorkflowStack
 * @typedef {import("rulesengine.io").Context} Context
 */

/**
 * @param {{
 *   data: T;
 *   prerequisiteResults: object[];
 *   context: Context;
 *   workflowStack: WorkflowStack[];
 *   dispatch: (data:object,context:Context,awaitResult?:boolean)=>Promise<void|any>
 *   log: LoggingProvider
 * }} parameters
 * @returns {T}
 */
async function createHaven(/*{ data, context }*/) {
    // Rethink this with Auth0 when you get back to havens
    throw new Error('not implemented');

    // Havens not supported for OAuth users yet - probably going rethink some of this
    // anyway to use Auth0 better -- relegating more responsibility to that platform.
    // Also, havens are currently only for sst users and we don't use Auth0.

    // Pass auth data at the same time so that the tenant selection
    // can be set to the newly created tenant inside the haven.
    // Unless there is more than one tenant or usecase created ... in which
    // case server should trigger the TenantSelection again after the
    // haven is created.
    // const { userName, password } = context.user;
    // const { newRecord } = data;
    // const meta = newRecord.meta || {};
    // delete newRecord.meta;
    // const combinedRecord = {
    //     meta,
    //     haven: newRecord,
    //     auth: {
    //         userName,
    //         password
    //     }
    // };
    // const httpHeaders = httpUtilities.getHttpHeaders({ ...newRecord.meta, correlationId: newRecord.correlationId });
    // const path = '/api/deploy/haven';
    // const result = await _private.post(path, combinedRecord, httpHeaders);
    // data.result = result;
    // return data;
}
