import { createElement as rc, useMemo, useEffect, useState } from 'react';
import { session as _session, constants } from 'lib_ui-services';
import { contexts, hooks } from 'lib_ui-primitives';
import useEventSink from '../../hooks/useEventSink';
import log from '@sstdev/lib_logging';

const { ASYNC_LOAD_STATUS } = constants;
const { useRouter } = hooks;

/** @type {import('react').Context<import('../../../../types').Profile>} */
export const SessionContext = contexts.SessionContext;

const _p = {
    useRouter,
    useEventSink,
    init: _session.init,
    onSessionChange: _session.onSessionChange
};
export const _private = _p;

export default function SessionProvider(props) {
    const [session, setSession] = useState({});
    const [status, setStatus] = useState(ASYNC_LOAD_STATUS.notStarted);
    const [sessionError, setSessionError] = useState(undefined);

    const { children } = props;

    // Login with Auth0
    useEffect(() => {
        let allowStateUpdate = true;
        const doAsync = async () => {
            try {
                const session = await _p.init();
                // If session is null, we are probably in the process of redirecting to the
                // login page (from the lib_ui-services session/index.js code).
                if (!allowStateUpdate || session == null) return;
                setSession(session);
                if (session.loggingOut) {
                    setStatus(ASYNC_LOAD_STATUS.loggingOut);
                    return;
                }
                setStatus(ASYNC_LOAD_STATUS.success);
            } catch (error) {
                log.error('[SESSION] ' + error.stack);
                if (!allowStateUpdate) return;
                setSessionError(error);
                setStatus(ASYNC_LOAD_STATUS.error);
            }
        };
        doAsync();
        setStatus(ASYNC_LOAD_STATUS.pending);
        return () => (allowStateUpdate = false);
    }, []);

    useEffect(() => {
        return _p.onSessionChange((type, session) => {
            if (type === 'session') {
                setSession(session);
                if (session.loggingOut) {
                    setStatus(ASYNC_LOAD_STATUS.loggingOut);
                    return;
                }
            }
        });
    }, []);

    const value = useMemo(() => ({ ...session, status, sessionError }), [session, status, sessionError]);
    // prettier-ignore
    return rc(SessionContext.Provider, { value }, children);
}
