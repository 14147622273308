import lodash from 'lodash';
const { omit } = lodash;
export default function match(criteria, _context) {
    // DEBUG A SCENARIO
    // if (context.type == null && context.namespace === 'item' ) {
    //     debugger;
    // }

    // destructuring is surprisingly expensive and this function is used all the time, so do
    // normal assignments instead.
    //const { verb, namespace, relation, status, featureFlags: _featureFlags = [], ...context } = _context;
    const verb = _context.verb;
    const namespace = _context.namespace;
    const relation = _context.relation;
    const status = _context.status;
    const _featureFlags = _context.featureFlags ?? [];
    const userFeatureFlags = _context?.session?.allFeatureFlags || [];
    const featureFlags = [..._featureFlags, ...userFeatureFlags];
    //first filter on any of the standard conditions:
    if (
        !criteria ||
        //these have to match:
        criteria.verb !== verb ||
        criteria.status !== status ||
        //IF these are defined on the rule, they have to match
        !emptyOrMatch(criteria.namespace, namespace) ||
        !emptyOrMatch(criteria.relation, relation)
    ) {
        return false;
    }
    //then filter on any additional properties on the rule
    //E.g. to limit by tenant, user, or the user's role or other permissions
    const ruleCriteria = extractCriteria(criteria);
    for (const key in ruleCriteria) {
        if (Object.hasOwnProperty.call(ruleCriteria, key)) {
            if (!emptyOrMatch(ruleCriteria[key], _context[key])) return false;
        }
    }
    //IF the rule has a featureFlag, that flag should also be on the context
    if (criteria.featureFlag && (!featureFlags || !featureFlags.includes(criteria.featureFlag))) return false;

    //IF the rule has an excludeFeatureFlag, that flag should NOT be on the context
    if (criteria.excludedFeatureFlag && featureFlags?.includes(criteria.excludedFeatureFlag)) return false;

    // if context ns/rel matches excluded ns/rel (or wildcard), then fail match.
    if (criteria.excludedNamespaceRelations) {
        if (
            criteria.excludedNamespaceRelations.some(
                ({ namespace, relation, type }) =>
                    namespace === _context.namespace &&
                    [undefined, _context.relation].includes(relation) &&
                    [undefined, _context.type].includes(type)
            )
        )
            return false;
    }
    if (criteria.useCaseIds) {
        if (!criteria.useCaseIds.includes(_context.session.useCase['metaui:useCase']._id)) return false;
    }
    if (criteria.excludedUseCaseIds) {
        if (criteria.excludedUseCaseIds.includes(_context.session.useCase['metaui:useCase']._id)) return false;
    }
    return true;
}

const emptyOrMatch = (value1, value2) => {
    if (value1 === undefined || value1 === '') return true;
    if (Array.isArray(value1)) {
        return value1.includes(value2);
    }
    return value1 === value2;
};

/* eslint-disable no-unused-vars */
const extractCriteria = rule => {
    // remove any known properties
    const criteria = omit(
        rule,
        'verb',
        'namespace',
        'relation',
        'status',
        'featureFlag',
        'excludedFeatureFlag',
        'priority',
        'excludedNamespaceRelations',
        'excludedUseCaseIds',
        'useCaseIds',
        'description',
        'logic',
        'onError',
        'prerequisites'
    );
    return criteria;
};
/* eslint-enable no-unused-vars */
