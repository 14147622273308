import lodash from 'lodash';
import { filters, constants, metadata } from 'lib_ui-services';

const { omit, memoize } = lodash;
const { ERROR, REMOVE_AND_ERROR, REMOVE } = constants.tagAction;
const {
    filterHelpers: { criteriaToJavaScript },
    createDataFilter
} = filters;

export const _private = {
    getDataDictionary: metadata.getDictionary
};

/**
 *
 * @param {{
 *    tagId:string,
 *    sensorType:string,
 *    rssi?:number,
 *    inDatabase?:boolean,
 * }} newEntry
 * @param {Array} dbResults
 * @param {{
 *    displayGatheredErrors:function,
 *    titularField:string,
 *    publish:function,
 *    request:function,
 *    matchProperties:Array<string>,
 *    displayInAscii:boolean,
 *    dataRights:import('../../../hooks/useProfileRole').Role
 *    mergeNamespace:string,
 *    mergeRelation:string,
 *    unknownTagAction:constants.tagAction,
 *    inactiveTagAction:constants.tagAction,
 *    beepForNewReads:boolean
 * }} config
 * @param {function} update
 * @param {function} remove
 * @returns {Promise<boolean>} True if the grid entry was updated, false if it was removed
 */
async function merge(entry, dbResults, config, update, remove) {
    // This is the maximum text that will fit in the current (2023-05-17)
    // statically sized grid columns
    const partialTitle = dbResults[0][config.titularField].substring(dbResults[0][config.titularField].length - 5);

    const newEntry = {
        ...omit(entry, ['rssi', 'batteryLevel']),
        // only generate a title. Don't include the actual data from the record:
        [config.titularField]: `<${constants.INACCESSIBLE_ITEM}...${partialTitle}>`,
        // technically, the item is in the database, but practically,
        // the user should not be able to do anything more with it than if it did NOT exist
        inDatabase: false,
        inaccessible: true
    };

    // always display unknown tag errors for barcode/manual entries
    if (
        [constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(entry.sensorType) ||
        [ERROR, REMOVE_AND_ERROR].includes(config.unknownTagAction)
    ) {
        const message = `You do not have access to the requested data (${entry.tagId}).`;
        config.displayGatheredErrors(message);
    }
    if (
        [constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(entry.sensorType) ||
        [REMOVE, REMOVE_AND_ERROR].includes(config.inactiveTagAction)
    ) {
        remove(entry);
        return false;
    }
    update(newEntry);
    return true;
}

/**
 *
 * @param {{
 *          [restrictedForeignKeyProperty:string]:Array<{_id:string, title:string}>
 *      }} dataRights
 * @param {string} mergeNamespace
 * @param {string} mergeRelation
 * @return {Promise<{hasAccess: (record:object)=>boolean}>}
 */
async function accessChecker(dataRights, mergeNamespace, mergeRelation) {
    if (!dataRights) {
        return {
            hasAccess: () => true
        };
    }
    const dataDictionary = await _private.getDataDictionary();
    const dataModel = dataDictionary?.[mergeNamespace]?.[mergeRelation];
    const filterCriteria = createDataFilter(mergeNamespace, mergeRelation, dataModel, { dataRights });

    const hasAccess = criteriaToJavaScript(filterCriteria);

    return {
        hasAccess
    };
}

export default { merge, memoizedAccessChecker: memoize(accessChecker), accessChecker };
