import { View, Text, fromTheme, styled, ScrollView, COLORS } from 'lib_ui-primitives';

export const PreviewContainer = styled(View).attrs({ name: 'preview-container' })`
    padding: ${fromTheme('viewPadding')};
    flex-direction: column;
    min-width: 224px;
    flex-grow: 1;
`;
PreviewContainer.displayName = 'PreviewContainer';

export const PreviewArea = styled(ScrollView).attrs({ name: 'preview-container' })`
    background-color: ${fromTheme('colorScheme', 'background-medium')};
    min-width: 260px;
    min-height: 140px;
    flex-direction: column;
    align-items: center;
    padding-top: ${fromTheme('viewPaddingMore')};
    flex-grow: 1;
    flex-shrink: 1;
`;
PreviewArea.displayName = 'PreviewArea';

export const Label = styled(View).attrs({ name: 'label' })`
    position: relative;
    background-color: #fff;
    width: ${props => props.labelWidth || 200}px;
    height: ${props => props.labelHeight || 100}px;
    border-radius: 5px;
    flex-shrink: 0;
    flex-grow: 0;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    margin: 0 ${fromTheme('viewMargin')} ${fromTheme('viewMargin')} ${fromTheme('viewMargin')};
    padding-top: 5px;
    padding-bottom: 5px;
    ${props => {
        if (props.backgroundImage) {
            return `
            &:before {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.7;
                background-image: url(${props.backgroundImage});
                background-repeat: no-repeat;
                background-size: 95% 35px;
                background-position: center;
            }`;
        }
    }}
`;

export const BarcodeLine = styled(View).attrs({ name: 'barcode' })`
    height: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
`;

export const PreviewLine = styled(View)`
    height: ${({ lineHeight }) => (lineHeight === 2 ? '34px' : '17px;')};
    line-height: 17px;
    text-align: center;
    overflow: visible;
`;

export const PreviewText = styled(Text)`
    color: ${({ isError }) => (isError ? COLORS.red : COLORS.trueBlack)};
    font-size: 12px;
    font-weight: bold;
    font-family: Arial, sans-serif;
    transform: scale(1, 1.2);
`;

export const FixedContainer = styled(View)`
    flex-shrink: 0;
`;
