import lodash from 'lodash';
const { omit } = lodash;
import conversion from '@sstdev/lib_epc-conversions';
import { constants } from 'lib_ui-services';
const { ERROR, REMOVE_AND_ERROR } = constants.tagAction;

/**
 *
 * @param {{
 *    tagId:string,
 *    sensorType:string,
 *    rssi?:number,
 *    inDatabase?:boolean,
 * }} entry
 * @param {Array} dbResults not applicable
 * @param {{
 *    displayGatheredErrors:function,
 *    titularField:string,
 *    publish:function,
 *    request:function,
 *    matchProperties:Array<string>,
 *    displayInAscii:boolean,
 *    dataRights:import('../../../hooks/useProfileRole').Role
 *    mergeNamespace:string,
 *    mergeRelation:string,
 *    unknownTagAction:constants.tagAction,
 *    inactiveTagAction:constants.tagAction,
 *    beepForNewReads:boolean
 * }} config
 * @param {function} update
 * @param {function} remove
 * @returns {Promise<boolean>} True if the grid entry was updated, false if it was removed
 */
async function merge(entry, dbResults, config, update, remove) {
    const hexLookup = [constants.sensorTypes.RFID, constants.sensorTypes.BLE].includes(entry.sensorType);

    let newEntry = { ...omit(entry, ['rssi', 'batteryLevel']) };
    newEntry.inDatabase = false;

    let partialTag = entry.tagId;
    if (config.displayInAscii && hexLookup) {
        partialTag = conversion.ascii.fromHex(partialTag);
    }
    // This is the maximum text that will fit in the current (2023-05-17)
    // statically sized grid columns
    partialTag = partialTag.substring(partialTag.length - 6);
    newEntry[config.titularField] = `<${constants.UNKNOWN_TAG}...${partialTag}>`;

    if ([ERROR, REMOVE_AND_ERROR].includes(config.unknownTagAction)) {
        // Only display unknown tag errors for barcode/manual entries
        if ([constants.sensorTypes.MANUAL, constants.sensorTypes.BARCODE].includes(entry.sensorType)) {
            const message = `${entry.tagId} does not exist in the database.`;
            config.displayGatheredErrors(message);
            if (config.unknownTagAction === REMOVE_AND_ERROR) {
                remove(entry);
                return false;
            }
        }
    }
    update(newEntry);
    return true;
}

export default { merge };
