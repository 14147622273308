import { lazy, createElement as rc, Suspense } from 'react';
import { Text } from 'lib_ui-primitives';

//Lazy load just in case camera stuff loads slow
export default function CameraProxy(props) {
    const Camera = lazy(() => import(/* webpackPrefetch: true */ './CameraInner'));
    const fallback = rc(Text, null, 'Loading...');
    // prettier-ignore
    return rc(Suspense, {fallback},
        rc(Camera, props)
    );
}
