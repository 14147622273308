import { session as _session } from 'lib_ui-services';

export default {
    verb: 'doingGet',
    namespace: 'security',
    relation: 'profile',
    description: 'get the current user profile',
    priority: 10,
    logic
};

async function logic() {
    const session = await _session.getSessionData();
    return { result: [session.profile] };
}
