import { constants, metadata } from 'lib_ui-services';
import { errors as _errors } from 'lib_ui-primitives';
import getAllFeatureFlags from '../../../../utilities/getAllFeatureFlags';

const _p = {
    getLocationInfo,
    asyncConfirmationModal,
    getMinimumForeignKeyFieldsFromDictionary: metadata.getMinimumForeignKeyFieldsFromDictionary
};
export const _private = _p;

export default {
    verb: 'doingMark',
    namespace: 'item',
    relation: 'item',
    type: 'all',
    description: 'Mark event to mark all the scan records as found and save them',
    prerequisites: [
        {
            context: {
                verb: 'get',
                namespace: 'location',
                relation: 'location',
                type: 'get'
            },
            query: ({ data }) => {
                return { _id: data.locationId };
            }
        },
        {
            context: { verb: 'get', namespace: 'inventory', relation: 'inventory', type: 'find' },
            query: () => ({
                criteria: { 'inventory:status.title': 'Active' }
            })
        }
    ],
    //this is the actual logic:
    logic: markAllAsFound
};

async function markAllAsFound({ data, context, prerequisiteResults, dispatch }) {
    if (getAllFeatureFlags(context).includes('multiInventory')) return;

    const locationRecords = prerequisiteResults?.[0]?.result ?? [];
    const activeInventory = prerequisiteResults?.[1]?.result ?? [];
    // Just in case we ended up here without active inventory
    if (!activeInventory || activeInventory.length === 0) {
        dispatch(
            { message: 'No active inventory found!  You must have an active inventory to mark items as found.' },
            {
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        return;
    }
    if (locationRecords.length > 1) {
        dispatch(
            { message: `Multiple location found for _id ${data.locationId}!` },
            {
                ...context,
                verb: 'pop',
                namespace: 'application',
                relation: 'notification'
            }
        );
        return;
    }
    const { foundBy, includeUnknownTags, records } = data;

    const location = await _p.getMinimumForeignKeyFieldsFromDictionary(
        locationRecords[0],
        'item',
        'item',
        'location',
        'location'
    );
    location['location:building'] = locationRecords[0]['location:building'];
    if (includeUnknownTags) {
        const count = records.filter(rec => rec.assetNo.startsWith(`<${constants.UNKNOWN_TAG}`)).length;
        const confirm = await _p.asyncConfirmationModal(
            {
                message: `${count} unknown tags will not be marked as found.  Continue?`
            },
            dispatch
        );
        if (!confirm) {
            throw new Error('This request was cancelled.');
        }
    }
    const knownTags = records.filter(rec => !rec.assetNo.startsWith(`<${constants.UNKNOWN_TAG}`));
    const result = await Promise.all(
        knownTags.map(async read => {
            if (read.inDatabase) {
                const locationInfo = await _p.getLocationInfo(location, dispatch);
                const {
                    result: [oldRecord]
                } = await dispatch(
                    { _id: read._id },
                    {
                        verb: 'get',
                        namespace: 'item',
                        relation: 'item',
                        type: 'get'
                    },
                    true
                );
                const inventory = [
                    {
                        'inventory:inventory': { _id: activeInventory[0]._id, title: activeInventory[0].title },
                        ...oldRecord?.inventory?.[0],
                        found: true,
                        foundDate: new Date().toISOString(),
                        foundBy,
                        foundByScan: [
                            constants.sensorTypes.BARCODE,
                            constants.sensorTypes.RFID,
                            constants.sensorTypes.BLE
                        ].includes(read.sensorType)
                    }
                ];
                const newRecord = {
                    ...oldRecord,
                    inventory,
                    'location:company': await _p.getMinimumForeignKeyFieldsFromDictionary(
                        locationInfo.company,
                        'item',
                        'item',
                        'location',
                        'company'
                    ),
                    'location:building': await _p.getMinimumForeignKeyFieldsFromDictionary(
                        locationInfo.building,
                        'item',
                        'item',
                        'location',
                        'building'
                    ),
                    'location:location': await _p.getMinimumForeignKeyFieldsFromDictionary(
                        locationInfo.location,
                        'item',
                        'item',
                        'location',
                        'location'
                    )
                };

                if (await isValid(newRecord, dispatch, context)) {
                    await dispatch(
                        { oldRecord, newRecord },
                        { verb: 'update', namespace: 'item', relation: 'item' },
                        true
                    );
                    return newRecord;
                }
            }
        })
    );
    return { ...data, result };
}

async function getLocationInfo(newLocation, dispatch) {
    let newLocationInfo = { location: newLocation };

    newLocationInfo['building'] = newLocation['location:building'];
    const buildingResp = await dispatch(
        {
            criteria: { _id: newLocationInfo.building._id }
        },
        { verb: 'get', namespace: 'location', relation: 'building', type: 'find' },
        true
    );

    newLocationInfo['company'] = buildingResp.result[0]['location:company'];
    return newLocationInfo;
}

async function isValid(newRecord, dispatch, context) {
    try {
        const { namespace, relation } = context;
        await dispatch({ newRecord }, { verb: 'validate', namespace, relation }, true);
        return true;
    } catch (err) {
        if (err instanceof _errors.ValidationError) {
            // TODO: Make asset in this string dynamic based on relation.
            let message = 'There was a problem saving this asset.';
            Object.entries(err.fieldMessages).forEach(([field, fieldMessage]) => {
                message += `\n   ${field}: ${fieldMessage}`;
            });
            dispatch({ isError: true, message }, { verb: 'pop', namespace: 'application', relation: 'notification' });
        }
        // creates a _failure result.
        throw err;
    }
}

async function asyncConfirmationModal(modalInfo, dispatch) {
    return new Promise(resolve => {
        dispatch(
            {
                message: 'Are you sure you want to make this change?',
                okButtonText: 'OK',
                cancelButtonText: 'CANCEL',
                okAction: () => resolve(true),
                cancelAction: () => resolve(false),
                ...modalInfo
            },
            { verb: 'confirm', namespace: 'application', relation: 'user' }
        );
    });
}
