import localforage from 'localforage';

let instances = {}; // localforage instances;

//Prepend a prefix to all keys. Typically this will be a tenantId. This avoids storage getting
//polluted when users switch between tenants.
let _prefix = '_global_';

export function getPrefix() {
    return _prefix || '';
}

export function setPrefix(prefix) {
    _prefix = prefix.toString();
}

function _getInstanceName(namespaceRelation = '', usePrefix = true) {
    return (usePrefix ? getPrefix() + namespaceRelation : namespaceRelation) || 'bbDefault';
}

export async function getKey(key, namespaceRelation = '', defaultValue, usePrefix = true) {
    const instance = getLocalForageInstance(localforage, _getInstanceName(namespaceRelation, usePrefix));
    return instance.getItem(key).then(value => {
        // 5/22/2023 in case we didn't find anything, just in case it was stored incorrectly before
        // see if it happened to be stored under "localForage".
        // This can probably be removed in the future
        if (typeof value === 'undefined' && namespaceRelation === '') {
            return getKey(key, 'bbDefault', defaultValue, usePrefix);
        }
        return value ?? defaultValue;
    });
}

export async function setKey(key, value, namespaceRelation = '', usePrefix = true) {
    const instance = getLocalForageInstance(localforage, _getInstanceName(namespaceRelation, usePrefix));
    return instance.setItem(key, value);
}

export async function deleteKey(key, namespaceRelation = '', usePrefix = true) {
    const instance = getLocalForageInstance(localforage, _getInstanceName(namespaceRelation, usePrefix));
    return instance.removeItem(key);
}

function getLocalForageInstance(localforage, name = 'bbDefault') {
    if (instances[name] == null) {
        instances[name] = localforage.createInstance({ name });
    }
    return instances[name];
}

export default { getKey, setKey, deleteKey, getPrefix, setPrefix };
